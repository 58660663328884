import React from "react";
import "./index.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import strategySvg from "../../../assets/svgs/services-svgs/strategy.svg";
import recruitmentSvg from "../../../assets/svgs/services-svgs/recruitement.svg";
import trainingSvg from "../../../assets/svgs/services-svgs/training.svg";
import employeeSvg from "../../../assets/svgs/services-svgs/employee.svg";
import organizationSvg from "../../../assets/svgs/services-svgs/organization.svg";
import { Col, Row } from "antd";

const ServicesCards: React.FC = () => {
  const cardsContent = [
    {
      icon: strategySvg,
      heading: "Strategy",
      description:
        "At MisGenie, our HR consultancy team crafts tailored solutions to help your company conquer obstacles and achieve its objectives by leveraging its unique challenges and opportunities.",
    },
    {
      icon: recruitmentSvg,
      heading: "Recruitment",
      description:
        "MisGenie specializes in selecting candidates not just for their skills, but also for their positive attitude and mindset, ensuring they seamlessly integrate into your team and contribute to its success from day one.",
    },
    {
      icon: trainingSvg,
      heading: "Training",
      description:
        "MisGenie's consultancy team provides innovative training concepts to elevate your staff's skills, driving performance levels beyond previous achievements and aligning with your business's financial objectives.",
    },
    {
      icon: employeeSvg,
      heading: "Employee Engagement",
      description:
        "MisGenie's advisors offer personalized strategies to maintain workforce enthusiasm and engagement, tailored to your unique requirements and organizational ethos, ensuring a successful working environment even amidst challenges.",
    },
    {
      icon: organizationSvg,
      heading: "Organizational Development",
      description:
        "With MisGenie's expertise in organizational development, you can unleash your organization's maximum potential.",
    },
  ];

  return (
    <div className="services-cards-container">
      <div className="card-section-heading-container">
        <span className="card-section-heading">
          What makes <span className="colored-heading">MisGenie</span> the top
          choice for Enterprise productivity?
        </span>
        <span className="colored-description">Solution</span>
      </div>
      <div className="cards-section">
        <Row gutter={[16, 16]}>
          {cardsContent.slice(0, 3).map((card, index) => (
            <Col span={24} xl={8}>
              <div className="services-card" key={index}>
                <div className="service-card-image">
                  <img
                    src={card.icon}
                    alt="Service Image"
                    className="service-image"
                  />
                </div>
                <div className="service-card-content">
                  <span className="card-heading">{card.heading}</span>
                  <span className="card-description">{card.description}</span>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Row gutter={[16, 16]}>
          {cardsContent.slice(3).map((card, index) => (
            <Col span={24} xl={12}>
              <div className="services-card-horizontal" key={index}>
                <div className="service-card-image">
                  <img
                    src={card.icon}
                    alt="Service Image"
                    className="service-image"
                  />
                </div>

                <div className="service-card-horizontal-content">
                  <span className="card-heading">{card.heading}</span>
                  <span className="card-description">{card.description}</span>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default ServicesCards;
