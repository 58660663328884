import React, { FC } from "react";
import PlainContentContainer from "../../../Platform/PlainContantContainer";
import BgContentContainer from "../../../Platform/BgContentContainer";
import { RecruitmentConstants } from "../../../../constants/PlatformAttributeConstants";
import { storeFrontQuestion } from "../../../../common/Genie/questions";


const PlatformRecruitment: FC = () => {
  return (
    <>
      <div>
        <div>
        <BgContentContainer
            imgSrc={
                RecruitmentConstants.Recruitment_Management_Dashboard_Overview.img
              }
              heading={
                RecruitmentConstants.Recruitment_Management_Dashboard_Overview
                  .heading
              }
              description={
                RecruitmentConstants.Recruitment_Management_Dashboard_Overview
                  .description
              }
              questionId={1}
              arrayName={storeFrontQuestion.recruitment}
          />
          <PlainContentContainer
             imgSrc={RecruitmentConstants.Recruitment_Management_Job_Post.img}
             heading={
               RecruitmentConstants.Recruitment_Management_Job_Post.heading
             }
             description={
               RecruitmentConstants.Recruitment_Management_Job_Post.description
             }
             questionId={2}
             arrayName={storeFrontQuestion.recruitment}
          />
          <BgContentContainer
            imgSrc={RecruitmentConstants.Recruitment_Management_CV_Bank.img}
            heading={
              RecruitmentConstants.Recruitment_Management_CV_Bank.heading
            }
            description={
              RecruitmentConstants.Recruitment_Management_CV_Bank.description
            }
            questionId={3}
            arrayName={storeFrontQuestion.recruitment}
            />
        </div>
      </div>
    </>
  );
};
export default PlatformRecruitment;
