import Footer from "../../common/Footer";
import DemoCard from "../Home/DemoCard";
import HeroSection from "./HeroSection";
import { InfoSection } from "./InfoSection";
import { DevelopersTeam, LeadershipTeam } from "./SeniorTeam";
import VideoSection from "./VIdeoSection/indexx";

const AboutUs = () => {
  return (
    <>
      <HeroSection />
      <InfoSection />
      <VideoSection />
      <LeadershipTeam />
      <DevelopersTeam />
      <DemoCard />
      <Footer />
    </>
  );
};

export default AboutUs;
