type QuestionAnswer = {
  id: number;
  question: string;
  answer?: string;
  url?: string;
  tag?: string;
};
type StoreFrontQuestionType = {
  pricing: QuestionAnswer[];
  enterprise: QuestionAnswer[];
  services: QuestionAnswer[];
  organization: QuestionAnswer[];
  home: QuestionAnswer[];
  Platform: QuestionAnswer[];
  performance: QuestionAnswer[];
  employee: QuestionAnswer[];
  policy: QuestionAnswer[];
  mobileapp: QuestionAnswer[];
  integerations: QuestionAnswer[];
  attendance: QuestionAnswer[];
  payroll: QuestionAnswer[];
  recruitment: QuestionAnswer[];
};
export const storeFrontQuestion: StoreFrontQuestionType = {
  enterprise: [
    {
      id: 1,
      question:
        "Seeker of synergy,are you interested in MisGenie's enterprise solution?🌟",
      answer:
        "Unlock the potential for streamlined collaboration, team alignment, and heightened productivity with MisGenie's comprehensive all-in-one solution tailored for enterprises. Experience increased efficiency, enhanced communication channels, and a significantly improved employee experience, all seamlessly integrated into one versatile platform.🚀",
      url: "/enterprise",
      tag: "enterprise solution",
    },
    {
      id: 2,
      question: "Wonder why MisGenie excels in Enterprise productivity?",
      answer:
        "At MisGenie, we pride ourselves on offering a comprehensive suite of features that redefine enterprise productivity. With our world-class security measures, robust automation capabilities, advanced controls & permissions, and the convenience of an all-in-one solution, we empower organizations to achieve enhanced productivity levels. Experience the synergy of these elements working seamlessly together to propel your business forward.🚀",
      url: "/enterprise",
      tag: "Enterprise productivity",
    },
    {
      id: 3,
      question:
        "Curious traveler, as you journey through the vast expanse of knowledge, are there still lingering questions whispering in the corridors of your mind? 🤔 Feel free to get in touch with our support team at: mailto:support@misgenie.com",
      url: "/enterprise",
      tag: "support",
    },
  ],
  services: [
    {
      id: 1,
      question:
        "Welcome, traveler! Ready to uncover the secrets of our services? ✨",
      answer:
        "Ah, marvelous choice, dear seeker of knowledge! Behold the splendor of MisGenie's services:Unite your teams and accomplish more together.Simplify collaboration, align your teams, and increase productivity with MisGenie's versatile all-in-one solution designed for enterprises. Experience increased efficiency, foster better communication, and bask in the glow of improved employee experiences. Embrace the magic of synergy with MisGenie! ✨",
      url: "/services",
      tag: "services",
    },
    {
      id: 2,
      question:
        "Oh, wise wanderer! Would you like to know about our enterprise productivity magic?",
      answer:
        "Ah, behold the essence of MisGenie's prowess, unveiled before you:At MisGenie, our band of skilled sorcerers pledges unwavering dedication to advancing your company's legacy. Through our consultancy services, we bestow upon your HR department a wealth of wisdom, ensuring mastery of our enchanted software for unparalleled productivity. Yet, our quest transcends mere enterprise prosperity.Our ethos is one of nurturing the distinct talents and abilities harbored within each soul of your organization, igniting a symphony of excellence. Envision the boundless horizons awaiting us as we unite, driven by a shared fervor for growth and achievement. Today marks the dawn of our shared journey towards triumph. Why delay? Let us embark upon this magical odyssey together! ✨",
      url: "/services",
      tag: "consultancy services",
    },
    {
      id: 3,
      question:
        "  Seeker of wisdom, Would you like to know about our Strategy secrets? 🌟",
      answer:
        "  Ah, behold the essence of our Strategy service, unveiled before you:Our HR consultancy wizards at MisGenie possess an unparalleled knack for discerning the unique challenges and boundless opportunities within your realm. Together, we embark on a journey of collaboration, surmounting any obstacles that thwart the realization of your noble objectives. With tailor-made solutions crafted expressly for your needs, success becomes an inevitability. 🌟",
      url: "/services",
      tag: "Strategy service",
    },
    {
      id: 4,
      question:
        "  O wise traveler, Would you like to know about our Recruitment service? 🌟",
      answer:
        "  Behold, the essence of our Recruitment service unfurls before you:At MisGenie, we grasp the profound depth of selecting the perfect addition to your team—it's not merely about skills, but about finding souls imbued with positivity and enthusiasm. Our consultants stand ready to aid you in selecting candidates who not only wield the necessary skills but also radiate a bright aura of optimism. With their seamless integration into your ranks, they shall become the catalysts of your organization's triumph from the very outset. 🌟",
      url: "/services",
      tag: "Recruitment service",
    },
    {
      id: 5,
      question:
        "  Oh, esteemed voyager, Would you like to know about our Training services? 🌟",
      answer:
        "  Witness the essence of our Training services, unveiled before you:Employee training, a cornerstone of success, holds the key to unlocking your business's financial aspirations. Our consultancy artisans weave innovative concepts and strategies, igniting a flame within your staff that propels them toward unprecedented heights of skill and performance. 🌟",
      url: "/services",
      tag: "Training services",
    },
    {
      id: 6,
      question:
        "  Seeker of workplace harmony, Would you like to know about our Employee Engagement? 🌟",
      answer:
        "  Behold the essence of our Employee Engagement service, unveiled before you:Sustaining the fervor and dedication of your workforce is paramount for a thriving workplace ecosystem. MisGenie's sage advisors offer bespoke guidance and tactics tailored to your unique needs and values, whether you face hurdles or seek to enrich the very fabric of your organizational ethos. 🌟",
      url: "/services",
      tag: "Employee Engagement",
    },
    {
      id: 7,
      question:
        "  Seeker of growth, Would you like to know about our Organizational Development? 🌟",
      answer:
        "  Behold the essence of our Organizational Development service, unfurling its mystical cloak before you:With MisGenie's mastery in organizational development, you hold the key to unlock your organization's full potential. Our consultants, like skilled alchemists, meticulously scrutinize your present state and join hands with you to refine your objectives and strategies, birthing forth a realm of fruitful enhancement and heightened value across every corner of your domain. 🌟 Together with MisGenie, success transcends mere chance—it becomes your destined reality. Embark with us on this transformative odyssey and behold your organization ascend to unparalleled heights!",
      url: "/services",
      tag: "Organizational Development service",
    },
    {
      id: 8,
      question:
        "Curious traveler, as you journey through the vast expanse of knowledge, are there still lingering questions whispering in the corridors of your mind? 🤔 Feel free to get in touch with our support team at: mailto:support@misgenie.com",
      answer: "",
      url: "/services",
      tag: "support",
    },
  ],
  organization: [
    {
      id: 1,
      question:
        "  Welcome, traveler! Ready to uncover the secrets of Organization Management? ✨",
      answer:
        "  Behold the marvels of our Organization Management module:The Organization Management module simplifies HR, Administration, and Employee management with comprehensive tools. It includes detailed departmental structures, employee designations, job titles, and team hierarchies, streamlining communication and resource allocation. This fosters a collaborative work environment, driving organizational success through improved productivity and employee satisfaction.",
      url: "/Platform/organization",
      tag: "Organization Management",
    },
    {
      id: 2,
      question:
        "  Oh, seeker of knowledge, Are you curious about the domain of our Companies section? 🌟",
      answer:
        "  Behold the marvels of our Companies section:The company section within organization management acts as a central hub for key business information, offering insights into company names, specialties, and contact details. This repository enhances communication and collaboration across the organization, supporting strategic planning and resource allocation. It streamlines operations and fosters synergy among business units.",
      url: "/Platform/organization",
      tag: "Companies",
    },
    {
      id: 3,
      question:
        "  Oh, wise wanderer! Would you like to know about our Branches section?",
      answer:
        "  Witness the marvels of our Branches feature:The Branch feature within the organization component centralizes detailed information on each branch, facilitating informed decisions and efficient operations management. It offers easy access to locations, contact details, and operational specifics, enabling strategic planning and streamlined daily activities.",
      url: "/Platform/organization",
      tag: "Branches",
    },
    {
      id: 4,
      question:
        "  Oh, esteemed voyager, Would you like to know about our Departments section? 🌟",
      answer:
        "  Behold the wonders of this subsection:This subsection offers insights into departmental goals, structures, and functions, serving as a key information repository. It enhances understanding of the organization's inner workings, supports strategic decision-making, and promotes effective inter-departmental communication and collaboration, thereby boosting operational efficiency and synergy.",
      url: "/Platform/organization",
      tag: "Departments",
    },
    {
      id: 5,
      question:
        "  Oh, seeker of clarity, Would you like to know about our Employee Designations section?🌟",
      answer:
        "  Behold the wisdom of our Employee Designations module:This module clarifies employee responsibilities and designations, enhancing contribution to organizational goals. It categorizes employees by roles, improving efficiency and workflow, and facilitates clear communication by defining each individual's contribution to the company's objectives.",
      url: "/Platform/organization",
      tag: "Designations",
    },
    {
      id: 6,
      question:
        "  O seeker of professional identity, Would you like to know about our Job Title section? 🌟",
      answer:
        "  Behold the essence of our Job Titles section:The job titles section ensures uniform understanding of roles across the organization, clarifying responsibilities and streamlining communication. This standardization reduces ambiguity, fosters a cohesive culture, and supports talent management and recruitment by clearly defining position requirements and expectations.",
      url: "/Platform/organization",
      tag: "Job Title",
    },
    {
      id: 7,
      question:
        " Seeker of collaborative synergy, Would you like to know about our Teams Management section? 🌟",
      answer:
        "  Witness the marvels of Teams Management:Efficient collaboration is fostered through the inclusion of the feature of team organization within the management module. This outlines how teams are structured. By promoting a cohesive work environment and encouraging teamwork, it enhances productivity and accelerates project delivery. This functionality facilitates seamless communication and coordination among team members, enabling them to collaborate more effectively toward shared goals.",
      url: "/Platform/organization",
      tag: "Teams Management",
    },
    {
      id: 8,
      question:
        " Seeker of resourceful empowerment, Would you like to know about our Assets Creation section? 🌟",
      answer:
        "  Behold the wonders of Assets Creation:This asset management module streamlines the creation and tracking of company assets, detailing model, condition, and specifications. It centralizes information to promote transparency and informed decision-making for procurement, maintenance, and disposal, thus enhancing efficiency, reducing costs, and ensuring regulatory compliance.",
      url: "/Platform/organization",
      tag: "Assets Creation",
    },
    {
      id: 9,
      question:
        "  O seeker of resource allocation,Would you like to know about our Assets Assigning section?🌟",
      answer:
        "  Witness the marvels of Assets Assigning:This module streamlines asset allocation to employees and tracks usage, allowing admin and HR to assign assets efficiently by recording employee details and asset status. It improves transparency and accountability with tracking and reporting features, enhancing communication and ensuring assets support organizational goals effectively.",
      url: "/Platform/organization",
      tag: "Assets Assigning",
    },
    {
      id: 10,
      question:
        "Curious traveler, as you journey through the vast expanse of knowledge, are there still lingering questions whispering in the corridors of your mind? 🤔 Feel free to get in touch with our support team at: mailto:support@misgenie.com",
      answer: "",
      url: "/Platform/organization",
      tag: "support",
    },
  ],
  home: [
    {
      id: 1,
      question:
        "Welcome to MisGenie, where your organization's success is our priority! 🌟 With MisGenie.",
      answer: "",
      url: "/",
      tag: "home",
    },
    {
      id: 2,
      question:
        "Ready for MisGenie to revolutionize your organization? Streamlined efficiency awaits! Are you onboard?",
      answer:
        "Absolutely! With MisGenie, your organization is about to experience a remarkable shift. Manual processes will be a thing of the past as MisGenie ushers in a new era of streamlined efficiency. Get ready for a transformative journey ahead! 🌟",
      url: "/",
      tag: "Organizational Transformation",
    },
    {
      id: 3,
      question: "Ready to revolutionize people management with MisGenie?🌟",
      answer:
        "At MisGenie, we've crafted a user-friendly platform that simplifies people management tasks. From performance tracking to fostering employee development, our array of features ensures that managing your people has never been easier. With MisGenie, you can revolutionize your approach to people management and unlock new levels of efficiency and effectiveness.🌟",
      url: "/",
      tag: "people management",
    },
    {
      id: 4,
      question: "Seeking to streamline your culture management processes? 🌟",
      answer:
        "At MisGenie, we understand the vital importance of developing your culture for the success of your business. Our web-based HRIS portal is equipped with a comprehensive suite of people development tools, including performance tracking, training, and development features. With MisGenie, you can empower your employees to grow and thrive, ultimately transforming your organizational culture into one of continuous improvement and excellence.🌟",
      url: "/",
      tag: "culture management",
    },
    {
      id: 5,
      question: "Ready to streamline your talent management processes? 🌟",
      answer:
        "With MisGenie, your organization can seamlessly find, nurture, and retain top talent through our comprehensive talent management solutions. Our platform provides end-to-end support, from streamlined recruitment processes to efficient onboarding procedures. Utilizing our recruitment module, you can effortlessly attract and identify top talent using features like job postings, resume tracking, and candidate screening. Once the ideal candidate is selected, our onboarding module ensures a smooth transition, complete with digital paperwork, training modules, and performance tracking mechanisms. With MisGenie, you can trust in our ability to help you find, nurture, and retain the best talent, ensuring your organization's continued success.🌟",
      url: "/",
      tag: "talent management",
    },
    {
      id: 6,
      question:
        "Curious traveler, as you journey through the vast expanse of knowledge, are there still lingering questions whispering in the corridors of your mind? 🤔 Feel free to get in touch with our support team at: mailto:support@misgenie.com",
      answer: "Mail us at: mailto:support@misgenie.com🌟",
      url: "/",
      tag: "support",
    },
  ],
  Platform: [
    {
      id: 1,
      question: "Ready to transform HR with MisGenie's integrated portal?",
      answer:
        "MisGenie revolutionizes the way you handle HR tasks by delivering a seamless and integrated HRIS portal. Our platform simplifies your workflows, enhances employee engagement, and improves your bottom line. With MisGenie, you can streamline your HR processes effortlessly.",
      url: "/Platform",
      tag: "HRIS Transformation",
    },
    {
      id: 2,
      question: "Seeking to simplify HR administration tasks? ",
      answer:
        "With MisGenie, managing your people has never been easier. Our user-friendly platform offers a range of features designed to simplify HR administration tasks. From streamlined processes to comprehensive tools, MisGenie empowers you to efficiently manage your workforce.",
      url: "/Platform",
      tag: "HR administration",
    },
    {
      id: 3,
      question: "Ready to enhance your employee management processes? ",
      answer:
        "With MisGenie, managing your people has never been easier. Our user-friendly platform offers a range of features designed to enhance employee management processes. From streamlined workflows to intuitive tools, MisGenie empowers you to effectively oversee your workforce.",
      url: "/Platform",
      tag: "employee management",
    },

    {
      id: 4,
      question: "Ready to streamline your leave management processes? ",
      answer:
        "With MisGenie, managing leave has never been easier. Our user-friendly platform offers a range of features designed to streamline leave management processes. From automated workflows to intuitive interfaces, MisGenie empowers you to efficiently handle leave requests and ensure smooth operations.",
      url: "/Platform",
      tag: "leave management",
    },
    {
      id: 5,
      question:
        "Seeking to enhance your reporting and analytics capabilities? ",
      answer:
        "With MisGenie, managing reporting and analytics has never been easier. Our user-friendly platform offers a range of features designed to enhance reporting and analytics capabilities. From customizable dashboards to advanced data visualization tools, MisGenie empowers you to gain valuable insights and make informed decisions.",
      url: "/Platform",
      tag: "reporting and analytics",
    },
    {
      id: 7,
      question:
        "Curious traveler, as you journey through the vast expanse of knowledge, are there still lingering questions whispering in the corridors of your mind? 🤔 Feel free to get in touch with our support team at: mailto:support@misgenie.com",
      url: "/Platform",
      tag: "support",
    },
  ],
  performance: [
    {
      id: 1,
      question:
        "Empower admin and HR with MisGenie for performance management! 🚀 Ready to explore?",
      answer:
        "With MisGenie, empowering your admin and HR teams in performance management is effortless. 🌟 Our platform enables the creation and assessment of employee performance through defined KPI attributes, scales, and matrices. 📊 By fostering continuous improvement and goal alignment, MisGenie empowers your organization to thrive.",
      url: "/Platform/performance",
      tag: "Performance Management",
    },
    {
      id: 2,
      question:
        "Interested in defining key performance indicators (KPIs) with specific attributes and metrics? 📊",
      answer:
        "With MisGenie, defining key performance indicators (KPIs) with specific attributes and metrics is effortless. 🔍 Our platform enables effective evaluation of employee performance by providing detailed criteria for assessment. 🚀 With customizable settings, organizations can align KPIs with their strategic goals and objectives seamlessly, ensuring optimal performance and success.",
      url: "/Platform/performance",
      tag: "KPI attributes",
    },
    {
      id: 3,
      question:
        "Curious about establishing performance matrices for evaluating and tracking employee progress? 📈",
      answer:
        "With MisGenie, establishing performance matrices for evaluating and tracking employee progress is a breeze. 🌟 Our platform enables organizations to align employee contributions with overarching organizational goals, fostering clarity and accountability. 🔍 With detailed metrics and tracking capabilities, MisGenie facilitates continuous improvement and strategic alignment within the workforce, ensuring optimal performance and success.",
      url: "/Platform/performance",
      tag: "performance matrices",
    },

    {
      id: 4,
      question:
        "Ready to create customized scales for assessing KPIs with precision? 📊",
      answer:
        "With MisGenie, creating customized scales for assessing KPIs is seamless. 🌟 Our platform offers flexibility in defining assessment criteria, allowing organizations to align performance evaluations with specific goals and objectives effectively. 🔍 With customizable scales, MisGenie ensures accurate and comprehensive assessment of employee performance across various metrics, empowering organizations to make informed decisions and drive success.",
      url: "/Platform/performance",
      tag: "Assessing KPIs",
    },
    {
      id: 5,
      question:
        "Curious traveler, as you journey through the vast expanse of knowledge, are there still lingering questions whispering in the corridors of your mind? 🤔 Feel free to get in touch with our support team at: mailto:support@misgenie.com",
      url: "/Platform/performance",
      tag: "support",
    },
  ],
  employee: [
    {
      id: 1,
      question:
        "  Welcome, traveler! Ready to uncover the secrets of Employee Management? ✨",
      answer:
        "  Behold the essence of Employee Management:The employee management section centralizes detailed profiles, enhancing organizational efficiency by streamlining communication and supporting HR tasks. It aids in quick decision-making for project assignments, promotions, and managing company benefits.",
      url: "/Platform/employee",
      tag: "Employee Management",
    },
    {
      id: 2,
      question:
        "  O seeker of comprehensive insight, Would you like to know about Employee Profiles Management? 🌟",
      answer:
        "  Witness the marvels of Employee Profiles Management:The Employee Profiles Management module consolidates personnel data in the HR system, providing insights into employees' backgrounds, qualifications, and skills. It boosts HR efficiency by enabling quick access for decision-making and team composition, offering advanced search features to find ideal candidates. Integrated with key HR functions and equipped with strict privacy controls, it fosters a collaborative and secure workplace.",
      url: "/Platform/employee",
      tag: "Employee Profiles Management",
    },
    {
      id: 3,
      question:
        "  Seeker of wisdom, Would you like to know about Contact Information Management? 🌟",
      answer:
        "  Behold the essence of Contact Information Management:The Contact Information Management module enhances internal communication by centralizing employee contact details, facilitating quick and easy collaboration. It simplifies reaching out to colleagues and supports efficient teamwork by ensuring all necessary contact information is readily available for immediate use.",
      url: "/Platform/employee",
      tag: "Contact Information",
    },
    {
      id: 4,
      question:
        "  Oh, esteemed voyager, Would you like to know about Work Experience Management? 🌟",
      answer:
        "🌟 Behold the wonders of the Work Experience Management module:The Work Experience Management module offers a detailed view of each employee's work history, including roles, responsibilities, and achievements, to better understand their professional growth and capabilities. It supports strategic HR decisions for promotions, project staffing, and team building by providing insights into experience and skill development.  ",
      url: "/Platform/employee",
      tag: "Work Experience",
    },
    {
      id: 5,
      question:
        "🌟 Seeker of mastery and expertise, Would you like to know about Skills Management?🌟",
      answer:
        "🌟 Witness the marvels of the Skills Management module:The Skills Management module catalogs employees' skills and expertise, providing a clear overview of the organization's talent pool. It helps managers and HR identify strengths for projects, team building, and development plans. This module enhances personnel utilization, supports training programs, and aids in succession planning, boosting the company's agility and competitiveness.  ",
      url: "/Platform/employee",
      tag: "Skills Management",
    },
    {
      id: 6,
      question:
        "🌟 Seeker of recognition and achievement, Would you like to know about Certificaions Management?🌟",
      answer:
        "🌟 Behold the marvels of the Certifications Management module:The Certifications Management module streamlines the tracking of employee certifications, detailing the institute, date, and title of each credential. It facilitates the identification of qualified personnel for specific tasks, compliance, and training opportunities, enhancing HR's ability to manage professional development and maintain industry standards efficiently. This module ensures a well-organized and accessible certification database, supporting strategic planning and operational excellence.  ",
      url: "/Platform/employee",
      tag: "Certificaions Management",
    },
    {
      id: 7,
      question:
        "🌟 Oh, seeker of expertise and qualifications, Would you like to know about Qualifications Management?🌟",
      answer:
        "🌟 Witness the marvels of the Qualifications Management module:The Qualifications Management module streamlines the assessment of employees' educational backgrounds, facilitating role suitability evaluations and workforce planning. It records academic achievements, supporting strategic training, and development initiatives. This efficient overview of qualifications aids in aligning personnel with organizational goals, enhancing decision-making and promoting a skilled workforce.  ",
      url: "/Platform/employee",
      tag: "Qualifications Management",
    },
    {
      id: 8,
      question:
        "  Oh, wise wanderer! Would you like to know about Relative Details Management?🌟",
      answer:
        "🌟 Behold the wonders of the Relative Details Management module:The Relative Details Management module enriches employee profiles with relatives' information, aiding in emergency contact management, benefits administration, and fostering work-life balance. It supports a more inclusive work environment by recognizing family contexts, enhancing policy development, and ensuring compliance. This streamlined approach to HR management promotes a supportive and informed organizational culture.  ",
      url: "/Platform/employee",
      tag: "Relative Details",
    },
    {
      id: 9,
      question:
        "🌟 Esteemed guardian of financial fortitude,Would you like to know about Banks Management?🌟",
      answer:
        "🌟 Behold the marvels of the Banks Management section:The Banks Management section centralizes team members' banking information, enhancing payroll and financial transactions. It improves the efficiency and accuracy of salary disbursements and expense reimbursements, ensuring secure and timely financial operations while upholding confidentiality and compliance standards.  ",
      url: "/Platform/employee",
      tag: "Banks Management",
    },
    {
      id: 10,
      question:
        "  Oh, wise wanderer! Would you like to know about Salaries Management?",
      answer:
        "🌟 Witness the wonders of Salaries Management:The Salaries Management aspect focuses on streamlining salary administration, directly contributing to enhanced organizational efficiency. It simplifies the process of calculating, distributing, and managing wages, ensuring accuracy and timely payments. This system supports financial planning and analysis, allowing for a more strategic allocation of resources and improving employee satisfaction through reliable compensation practices.  ",
      url: "/Platform/employee",
      tag: "Salaries Management",
    },
    {
      id: 11,
      question:
        "Curious traveler, as you journey through the vast expanse of knowledge, are there still lingering questions whispering in the corridors of your mind? 🤔 Feel free to get in touch with our support team at: mailto:support@misgenie.com",
      answer: "",
      url: "/Platform/employee",
      tag: "support",
    },
  ],
  recruitment: [
    {
      id: 1,
      question: "  Ready to uncover the secrets of Recruitment Management?",
      answer:
        "🌟 Witness the power of Recruitment Management:This recruitment tool empowers HR professionals with a comprehensive platform for talent acquisition. It enables meticulous assessment of candidate qualifications, strategic analysis of staffing needs, and optimization of job postings. By centralizing recruitment tasks, it facilitates efficient management from job requisition to candidate selection, enhancing collaboration and communication while ensuring informed, timely decisions aligned with organizational goals.  ",
      url: "/Platform/recruitment",
      tag: "Recruitment Management",
    },
    {
      id: 2,
      question: "  Interested in exploring Recruitment Dashboards?",
      answer:
        "  Behold the wonders of the Dashboard Overview:This module provides HR with a detailed overview of recruitment activities, enabling thorough assessment of candidate qualifications, staffing requirements, and the effectiveness of job postings, streamlining decision-making processes.  ",
      url: "/Platform/recruitment",
      tag: "Recruitment Dashboard",
    },
    {
      id: 3,
      question: "  Want to discover the magic of Job Posts Management Module?",
      answer:
        "  Witness the power of Job Posts Management:Providing a centralized platform for recruitment oversight, this feature allows HR to gain a comprehensive understanding of candidate profiles, staffing needs, and the performance of job postings. It streamlines decision-making processes by offering a holistic view of recruitment activities, empowering HR to optimize their strategies effectively.  ",
      url: "/Platform/recruitment",
      tag: "Job Posts Management",
    },
    {
      id: 4,
      question: "  Ever wondered about the mysteries of the CV Bank?",
      answer:
        "  Behold the efficiency of Job Posts Management:Simplifying the hiring process, this sub-feature offers HRs convenient access to candidate resumes and qualifications stored in a centralized database. By providing easy retrieval and review of CVs, it streamlines the candidate selection process, enabling HRs to make informed decisions efficiently.  ",
      url: "/Platform/recruitment",
      tag: "CV Bank",
    },
    {
      id: 5,
      question:
        "  Curious traveler, as you journey through the vast expanse of knowledge, are there still lingering questions whispering in the corridors of your mind?",
      answer: "",
      url: "/Platform/recruitment",
    },
  ],

  payroll: [
    {
      id: 1,
      question: "  Ready to uncover the secrets of Payroll Management?",
      answer:
        "  Witness the marvels of the Payroll Management system:The payroll system efficiently manages various aspects of employee compensation, including salaries, deductions, perks, bonuses, taxes, and provident funds. It generates and maintains detailed employee salary slips, ensuring accurate record-keeping and transparent financial transactions. With its comprehensive functionality, the system simplifies payroll processing and enhances administrative efficiency within the organization.  ",
      url: "/Platform/payroll",
      tag: "Payroll Management",
    },
    {
      id: 2,
      question: "  Curious about Employment Benefits?",
      answer:
        "  Behold the wonders of Employment Benefits:This feature simplifies the management of employee benefits such as healthcare, insurance, and retirement plans. It offers a user-friendly platform for HR to efficiently administer and track benefits, ensuring employee satisfaction and compliance with company policies.  ",
      url: "/Platform/payroll",
      tag: "Employment Benefits",
    },
    {
      id: 3,
      question: "  Interested in knowing about Salaries Management?",
      answer:
        "  Behold the efficiency of the Salaries feature:This feature streamlines salary calculations, deductions, and payment processing tasks. It provides an efficient platform for HR to manage payroll, ensuring accurate and timely salary disbursements while maintaining compliance with regulations.  ",
      url: "/Platform/payroll",
      tag: "Salaries Management",
    },
    {
      id: 4,
      question: "  Want to explore Pay Slip Generation?",
      answer:
        "  Witness the marvels of Pay Slip Generation:This feature automates the creation of detailed pay slips, offering transparency in employees' earnings and deductions. It ensures accurate documentation of payroll information, enhancing communication and trust between employees and the organization.  ",
      url: "/Platform/payroll",
      tag: "Pay Slip Generation",
    },
    {
      id: 5,
      question: "  Eager to learn about Overtime Claim?",
      answer:
        "  Behold the marvels of Overtime Claim:This feature streamlines the recording and compensation of overtime hours worked by employees. It simplifies the process, ensuring accurate tracking and timely payment for additional hours put in by staff members.  ",
      url: "/Platform/payroll",
      tag: "Overtime Claim",
    },
    {
      id: 6,
      question: "  Want to know about Claim Requests?",
      answer:
        "  Witness the marvels of Claim Requests: This functionality facilitates the seamless submission and processing of various employee claims. It streamlines the request process, ensuring timely approval and efficient management of reimbursements, expenses, and other claims. With automated workflows and easy tracking, it enhances transparency and reduces administrative burden for HR teams.  ",
      url: "/Platform/payroll",
      tag: "Claim Requests",
    },
    {
      id: 7,
      question: "  Seeking information on Payroll Configuration?",
      answer:
        "  Behold the wonders of Payroll Configuration: This tool offers customizable settings to tailor the payroll system to specific organizational needs. It enables administrators to configure salary structures, deductions, tax rules, and payment schedules with ease. With flexible options and comprehensive controls, it ensures accurate and compliant payroll processing while accommodating diverse employee requirements.  ",
      url: "/Platform/payroll",
      tag: "Payroll Configuration",
    },
    {
      id: 8,
      question: "  Want to know about Taxation Management?",
      answer:
        "  Witness the marvels of Taxation Management: This feature simplifies the handling of tax-related tasks within the organization. It provides tools for calculating, withholding, and reporting taxes accurately, ensuring compliance with tax laws and regulations. With streamlined processes and automated functionalities, it helps optimize tax management while reducing administrative burdens for HR and finance teams.  ",
      url: "/Platform/payroll",
      tag: "Taxation Management",
    },
    {
      id: 9,
      question:
        "Curious traveler, as you journey through the vast expanse of knowledge, are there still lingering questions whispering in the corridors of your mind? 🤔 Feel free to get in touch with our support team at: support@misgenie.com",
      answer: "",
      url: "/Platform/payroll",
    },
  ],

  attendance: [
    {
      id: 1,
      question: "  Ready to uncover the secrets of Attendance Management? ✨",
      answer:
        "🌟 Behold the marvels of the Attendance Management module:The attendance module provides HR with a detailed monthly report, encompassing employee attendance data like check-in/out times and work hours. This information aids in precise salary calculations and enables informed decisions for fair compensation practices.  ",
      url: "/Platform/attendance",
      tag: "Attendance Management",
    },
    {
      id: 2,
      question: "🌟 Interested in learning about the Attendance Module?",
      answer:
        "🌟 Witness the marvels of the attendance module module: This module streamlines HR's tracking of employee attendance with a detailed monthly sheet, providing a comprehensive overview that aids in effective analysis and management.  ",
      url: "/Platform/attendance",
      tag: "Attendance Module",
    },
    {
      id: 3,
      question: "🌟 Want to explore Attendance Logs Management?",
      answer:
        "🌟 Through the wonders of Attendance Logs Management:This module records precise check-in and check-out times, offering HR insights into employee punctuality and adherence to work hours. It aids in assessing attendance patterns, identifying trends, and fostering accountability.  ",
      url: "/Platform/attendance",
      tag: "Attendance Logs Management",
    },
    {
      id: 4,
      question: "  Eager to know about Leave Approval Management?",
      answer:
        "🌟 Behold the efficiency of Leave Approval Management:Streamline the process of requesting and approving leave with this efficient module. From submitting requests to tracking approvals, it offers a centralized platform for managing employee leave effectively. Simplify workflow, enhance transparency, and ensure timely responses with Leave Approval Management.  ",
      url: "/Platform/attendance",
      tag: "Leave Approval Management",
    },
    {
      id: 5,
      question:
        "Curious traveler, as you journey through the vast expanse of knowledge, are there still lingering questions whispering in the corridors of your mind? 🤔 Feel free to get in touch with our support team at: support@misgenie.com",
      answer: "",
      url: "/Platform/attendance",
    },
  ],

  mobileapp: [
    {
      id: 1,
      question:
        "📱 Interested in exploring the MisGenie Mobile App? 📱 Ready to discover its features and functionalities?",
      answer:
        "With the MisGenie Mobile App, you can access a variety of features designed to streamline your HR processes. From viewing the landing page to exploring details about MisGenie and its services, the app offers convenience at your fingertips. Additionally, you can preview the dashboard, manage attendance, and submit leave requests seamlessly. Let the MisGenie Mobile App revolutionize your HR experience.",
      url: "/Platform/mobileapp",
      tag: "MisGenie Mobile App",
    },
    {
      id: 2,
      question:
        "📱 Ready to put the entire HRMS experience in the hands of your workforce with the MisGenie Mobile App? 📱 Wondering how connectivity enhances HR efficiency?",
      answer:
        "With the MisGenie Mobile App, connectivity is key. 🌟 Our user-friendly dashboard empowers HR staff to effortlessly access insights on employee data, including check-in/out times, attendance, onboarding details, work anniversaries, and more. ⏰ Additionally, the attendance module provides HR staff with a detailed monthly overview of employee attendance, including check-in/out times, late arrivals, missed punches, workable hours, and total work hours. 📅 Team members can also utilize the Requests module to lodge leave requests for time off, vacations, or temporary absences from work. Let MisGenie revolutionize your HR experience by putting powerful tools at your fingertips.",
      url: "/Platform/mobileapp",
      tag: "HRMS Mobile Experience",
    },
    {
      id: 3,
      question:
        "Curious traveler, as you journey through the vast expanse of knowledge, are there still lingering questions whispering in the corridors of your mind? 🤔 Feel free to get in touch with our support team at: support@misgenie.com",
      url: "/Platform/mobileapp",
      tag: "Support",
    },
  ],

  integerations: [
    {
      id: 1,
      question:
        "Curious about the integrations offered by MisGenie? 🤝 Wondering how they enhance user experience?",
      answer:
        "MisGenie offers integrations with various platforms, including Jira, Slack, Microsoft Teams, QuickBooks, and more. 🌟 With these integrations, users can enjoy a seamless experience across multiple applications, enhancing productivity and collaboration. Here's a glimpse of what each integration offers: 1. Jira: A project management tool for tracking tasks, issues, and software development processes. 2. Slack: A messaging app for teams, facilitating communication and collaboration in real-time. 3. Microsoft Teams: A unified communication platform for chat, meetings, file sharing, and collaboration within organizations. 4. QuickBooks: Accounting software designed for small businesses, offering invoicing, expense tracking, and financial reporting. Let MisGenie streamline your workflow by integrating with your favorite tools.",
      url: "/Platform/integerations",
      tag: "MisGenie Integrations",
    },
    {
      id: 2,
      question:
        "Curious traveler, as you journey through the vast expanse of knowledge, are there still lingering questions whispering in the corridors of your mind? 🤔 Feel free to get in touch with our support team at: support@misgenie.com",
      url: "/Platform/integerations",
      tag: "Support",
    },
  ],

  policy: [
    {
      id: 1,
      question:
        "Ready to empower your admin and HR teams in creating and managing policies for employees? 🚀",
      answer:
        "With MisGenie, empowering your admin and HR teams to create and manage policies for employees is effortless. 🌟 Our platform ensures clear guidelines and compliance, empowering your organization to thrive.",
      url: "/Platform/policy",
      tag: "Policy Management",
    },
    {
      id: 2,
      question:
        "Ready to empower your admin and HR teams to craft and oversee comprehensive policies for employees? 📝",
      answer:
        "With MisGenie, empowering your admin and HR teams to efficiently craft and oversee policies for employees is effortless. 🌟 Ensure clarity, consistency, and compliance in your organization's policies with MisGenie.",
      url: "/Platform/policy",
      tag: "Policy Oversight",
    },
    {
      id: 3,
      question: "Curious about establishing deduction policies accurately? 💼",
      answer:
        "With MisGenie, establishing deduction policies is effortless. 🌟 Customize policies to align with your strategic goals and objectives seamlessly.",
      url: "/Platform/policy",
      tag: "Deduction Policies",
    },
    {
      id: 4,
      question:
        "Ready to establish clear timing policies for work schedules and attendance? ⏰",
      answer:
        "With MisGenie, establishing clear timing policies is seamless. 🌟 Optimize operational effectiveness and promote accountability within your organization.",
      url: "/Platform/policy",
      tag: "Timing Policies",
    },
    {
      id: 5,
      question: "Ready to develop comprehensive overtime policies? ⏳",
      answer:
        "With MisGenie, developing comprehensive overtime policies is seamless. 🌟 Ensure accuracy and fairness in overtime management.",
      url: "/Platform/policy",
      tag: "Overtime Policies",
    },
    {
      id: 6,
      question: "Ready to establish guidelines for employee absenteeism? 📅",
      answer:
        "With MisGenie, establishing guidelines for employee absenteeism is effortless. 🌟 Foster reliability and accountability within your organization.",
      url: "/Platform/policy",
      tag: "Absenteeism Policies",
    },
    {
      id: 7,
      question: "Ready to craft comprehensive leave policies? 🌴",
      answer:
        "With MisGenie, crafting comprehensive leave policies is seamless. 🌟 Promote transparency and accountability within your organization.",
      url: "/Platform/policy",
      tag: "Leave Policies",
    },
    {
      id: 8,
      question:
        "Curious traveler, as you journey through the vast expanse of knowledge, are there still lingering questions whispering in the corridors of your mind? 🤔 Feel free to get in touch with our support team at: support@misgenie.com",
      url: "/Platform/integerations",
      tag: "Support",
    },
  ],

  pricing: [
    {
      id: 1,
      question:
        "Perplexed with subscription bundles? Let me guide you through the features ✨",
      answer:
        "Our most sold services: i) Organization Management ii) Core HR iii) Attendance & Leave iv) Payroll",
      url: "/pricing",
      tag: "Subscription Bundles",
    },
    {
      id: 2,
      question: "Explore our payment options 🌟",
      answer:
        "We accept credit cards and bank transfers. Choose your method during checkout.",
      url: "/pricing",
      tag: "Payment Options",
    },
    {
      id: 3,
      question: "Want to know about our free trial? 🆓",
      answer: "We offer a 14-day free trial. Try it before subscribing.",
      url: "/pricing",
      tag: "Free Trial",
    },
    {
      id: 4,
      question: "Any limit on platform users? 🤔",
      answer: "No limit. Add as many users as you need.",
      url: "/pricing",
      tag: "User Limit",
    },
    {
      id: 5,
      question: "Curious about support levels? Shall I enlighten you? 🧞‍♂️",
      answer:
        "All plans include email support. Higher-level plans offer phone and chat support.",
      url: "/pricing",
      tag: "Support Options",
    },
    {
      id: 6,
      question: "Wondering about subscription flexibility? 🌈",
      answer: "Upgrade or downgrade anytime. Log in and make changes.",
      url: "/pricing",
      tag: "Subscription Flexibility",
    },
    {
      id: 7,
      question: "Consequences of cancellation? Shall I reveal? ❓",
      answer: "Account stays active till end of billing cycle. Then no access.",
      url: "/pricing",
      tag: "Cancellation Policy",
    },
    {
      id: 8,
      question:
        "Curious traveler, as you journey through the vast expanse of knowledge, are there still lingering questions whispering in the corridors of your mind? 🤔 Feel free to get in touch with our support team at: support@misgenie.com",
      url: "/Platform/integerations",
      tag: "Support",
    },
  ],
};
