import React, { useState, useEffect, useRef } from "react";
import { Layout } from "antd";
import "./index.css";
import { Outlet } from "react-router-dom";
import Navbar from "../common/Header";

const { Content } = Layout;

const LandingPagesLayout: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current?.scrollTop as any > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    const currentContentRef = contentRef.current;
    currentContentRef?.addEventListener("scroll", handleScroll);

    return () => {
      currentContentRef?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Layout className="landing-pages-layout">
      <Navbar isScrolled={isScrolled} />
      <div className="custom-scrollbar-container" ref={contentRef}>
        <Content className="content-main-layout">
          <Outlet />
        </Content>
      </div>
    </Layout>
  );
};

export default LandingPagesLayout;
