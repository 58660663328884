import "./index.css";
import DemoVideoProps from "../../../common/DemoVideo";
const VideoSection: React.FC = () => {
  return (
    <div className="video-main-container">
      <div className="video-card-container">
        <DemoVideoProps videoId="8Mp2DdLDipA" />
      </div>
    </div>
  );
};

export default VideoSection;
