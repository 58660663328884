import "./index.css";

const HeroSection: React.FC = () => {

  return (
    <div className="terms-hero-container">
      <div className="terms-hero-content-container">
        <span className="terms-hero-heading storefront-hero-heading">
          Terms and Conditions
        </span>
      </div>
    </div>
  );
};

export default HeroSection;
