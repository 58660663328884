import "./index.css";
import logoWhite from "../../assets/images/logo-white.svg";
import instagramSvg from "../../assets/svgs/home-svgs/media-svgs/instagram.svg";
import linkedInSvg from "../../assets/svgs/home-svgs/media-svgs/linkedin.svg";
import facebookSvg from "../../assets/svgs/home-svgs/media-svgs/facebook.svg";
import youtubeSvg from "../../assets/svgs/home-svgs/media-svgs/youtube.svg";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "antd";

const Footer: React.FC = () => {
  const navigate = useNavigate();

  const handleOurStoryClick = () => {
    navigate("/about-us");
  };

  const handleCareersClick = () => {
    navigate("/about-us");
  };

  const handleOurTeamClick = () => {
    navigate("/about-us");
  };

  const handlePrivacyPolicyClick = () => {
    navigate("/privacy-policy");
  };

  const handleCookiePolicyClick = () => {
    navigate("/cookie-policy");
  };

  const handleTermsAndConditionsClick = () => {
    navigate("/terms-and-conditions");
  };

  return (
    <div className="footer-container">
      <div className="colored-text-misgenie">MISGENIE</div>
      <div className="footer-content">
        <Row>
          <Col span={24} md={8} lg={9} style={{ zIndex: 10 }}>
            <div className="footer-left-content">
              <img src={logoWhite} alt="logo" width="200px" height="auto" />
              <p className="footer-text">
                With MisGenie, you can find, nurture, and retain the best talent
                for your organization. Our platform offers end-to-end talent
                management solutions, from streamlined recruitment to efficient
                onboarding.
              </p>
              <div className="media-links-container">
                <Link
                  to="https://www.instagram.com/misgenie.pk/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <img
                    src={instagramSvg}
                    alt="Instagram"
                    width="30px"
                    height="auto"
                  />
                </Link>
                <Link
                  to="https://www.linkedin.com/company/misgenie"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <img
                    src={linkedInSvg}
                    alt="LinkedIn"
                    width="30px"
                    height="auto"
                  />
                </Link>
                <Link
                  to="https://www.youtube.com/@Misgenie-pk"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <img
                    src={youtubeSvg}
                    alt="Youtube"
                    width="30px"
                    height="auto"
                  />
                </Link>

              </div>
            </div>
          </Col>

          <Col span={24} md={5} lg={5} style={{ zIndex: 10 }}>
            <div className="footer-right-col">
              <h3 className="footer-title">About Us</h3>
              <Link 
                to="/about-us"
                style={{ textDecoration: "none" }} className="footer-point">
                Our Story
              </Link>
              <Link   to="/about-us"   style={{ textDecoration: "none" }}  className="footer-point">
                Our Team
              </Link>
              <Link to="/about-us"   className="footer-point">
                Career
              </Link>
            </div>
          </Col>
          <Col span={24} md={5} lg={5} style={{ zIndex: 10 }}>
            <div className="footer-right-col">
              <h3 className="footer-title">Our Policies</h3>
              <span onClick={handlePrivacyPolicyClick} className="footer-point">
                Privacy Policy
              </span>
              <span onClick={handleCookiePolicyClick} className="footer-point">
                Cookie Policy
              </span>
              <span
                onClick={handleTermsAndConditionsClick}
                className="footer-point"
              >
                Terms and Conditions
              </span>
            </div>
          </Col>
          <Col span={24} md={6} lg={5} style={{ zIndex: 10 }}>
            <div className="footer-right-col">
              <h3 className="footer-title">Contact Us</h3>
              <span>
                <a
                  style={{ textDecoration: "none" }}
                  href="mailto:support@misgenie.com"
                  className="footer-point"
                >
                  support@misgenie.com
                </a>
              </span>
              <span>
                <a
                  style={{ textDecoration: "none" }}
                  href="tel:+923195559222"
                  className="footer-point"
                >
                  +92 319 5559222
                </a>
              </span>
              <span>
                <a
                  style={{ textDecoration: "none" }}
                  href="https://www.google.com/maps/dir//428+E+Block,+near+Allaho+Roundabout,+Johar+Town,+Lahore,+54782/@31.4682752,74.2137037,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x391903df0ca5b911:0xbc617ee6665c16dd!2m2!1d74.296105!2d31.4683016?entry=ttu"
                  className="footer-point"
                >
                  Johar Town, Lahore, Pakistan
                </a>
              </span>
            </div>
          </Col>
        </Row>

        {/* <div className="footer-right-content"></div> */}
      </div>
      <div className="footer-footer">
        <p className="footer-footer-text">
          © 2023 MisGenie. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
