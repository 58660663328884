import React, { useEffect, useState } from "react";
import { Drawer, Card, Button, Row, Col } from "antd";
import GenieImage from "../../../assets/images/store-front-genie.gif";
import "./index.css";
import Typewriter from "typewriter-effect";
interface GenieDrawerProps {
  visible: boolean;
  onClose: () => void;
  questionId: any;
  questionArray: any[];
}

interface GenieHistoryItem {
  id: number;
  q: string;
  ans: string;
}

const GenieDrawer: React.FC<GenieDrawerProps> = ({
  visible,
  onClose,
  questionArray,
  questionId,
}) => {
  const [questionObj, setQuestionObj] = useState<object>();
  const [showAnswer, setShowAnswer] = useState(false);
  const [tempId, setTempId] = useState<number | null>(null);
  const [genieHistory, setGenieHistory] = useState<GenieHistoryItem[]>([]);

  useEffect(() => {
    setTempId(questionId);
  }, [questionId, visible]);

  useEffect(() => {
    if (tempId) {
      const questionObject = questionArray.find(
        (question: any) => question.id === tempId
      );
      if (questionObject) {
        setQuestionObj(questionObject);
      }
    }
  }, [tempId, visible]);

  const handleOkClick = () => {
    setShowAnswer(true);
    if (questionObj && "question" in questionObj && "answer" in questionObj) {
      const { id, question, answer } = questionObj as {
        id: number;
        question: string;
        answer: string;
      };
      const duplicateValue = genieHistory.some((item) => item.id === id);
      if (!duplicateValue) {
        setGenieHistory([
          ...genieHistory,
          { id: id, q: question, ans: answer },
        ]);
      }
    }
  };

  const handleSomethingElseClick = () => {
    const randomIndex = Math.floor(Math.random() * questionArray.length);
    setShowAnswer(false);
    setTempId(questionArray[randomIndex].id);
    setQuestionObj(questionArray[randomIndex]);
    setShowAnswer(false);
  };

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={onClose}
      open={visible}
      width={600}
    >
      <div className="genie-drawer-container">
        <Row className="history-main-container">
          {genieHistory.map(
            (item, index) =>
              item.id !== tempId && (
                <Col span={24} className="history-container">
                  <strong key={index}>{item.q}</strong>
                  <p key={index}>{item.ans}</p>
                </Col>
              )
          )}
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Col span={24}>
            <Card className="genie-question-card genie-question">
              <p>
                <Typewriter
                  options={{
                    strings: [(questionObj as any)?.question || " "],
                    autoStart: true,
                    loop: true,
                    delay: 30,
                    deleteSpeed: Infinity,
                  }}
                />
              </p>
            </Card>
          </Col>
        </Row>
        <Row className="genie-btn-container">
          <Col span={6}>
            <img className="genie-img" src={GenieImage} alt="Genie" />
          </Col>

          {!showAnswer && (
            <>
              {questionObj &&
              "answer" in questionObj &&
              (questionObj as any).answer &&
              (questionObj as any).answer.length === 0 ? (
                " "
              ) : (
                <Button onClick={handleOkClick} block className="genie-btn ">
                  Let the magic begin ✨{" "}
                </Button>
              )}
            </>
          )}

          <Button
            className="global-default-btn custom-primary-btn-width explore-btn"
            onClick={handleSomethingElseClick}
            block
          >
            Explore further 🌍
          </Button>
        </Row>

        <Row justify="center" style={{ marginTop: 20 }}>
          <Col span={24}>
            {showAnswer && (
              <Card className="genie-question-card genie-answer ">
                <p>
                  <Typewriter
                    options={{
                      strings: [(questionObj as any)?.answer || " "],
                      autoStart: true,
                      loop: true,
                      delay: 30,
                      deleteSpeed: Infinity,
                    }}
                  />
                </p>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </Drawer>
  );
};

export default GenieDrawer;
