import React from 'react';
import "./index.css"
const CookiesPolicy: React.FC = () => {
  return (
    <div className="cookies-policy-container">
      <p><strong>Last Updated:</strong> 1 September 2024</p>

      <p>
        This Cookies Policy explains how MisGenie ("we", "us", or "our") uses cookies and similar tracking technologies when you visit our website or use our services. By continuing to use our website, you agree to the use of cookies as outlined in this policy.
      </p>

      <h2>1. What Are Cookies?</h2>
      <p>
        Cookies are small text files that are placed on your device (computer, tablet, or mobile phone) when you visit a website. Cookies help websites function efficiently and provide information to the website owners to improve your experience.
      </p>

      <h2>2. How We Use Cookies</h2>
      <p>
        MisGenie uses cookies for various purposes, including:
      </p>
      <ul>
        <li><strong>Essential Cookies:</strong> These are necessary for the basic functionality of our website, such as allowing you to log into secure areas.</li>
        <li><strong>Performance Cookies:</strong> These cookies collect information about how visitors use our site, such as which pages are visited most often, allowing us to improve the site’s functionality.</li>
        <li><strong>Functionality Cookies:</strong> These are used to recognize you when you return to our website and to personalize your experience by remembering your preferences.</li>
        <li><strong>Advertising Cookies:</strong> These cookies track your online activity and deliver targeted advertisements based on your browsing behavior and interests.</li>
      </ul>

      <h2>3. Types of Cookies We Use</h2>
      <p>We use both session cookies and persistent cookies:</p>
      <ul>
        <li><strong>Session Cookies:</strong> These cookies are temporary and expire once you close your browser. They are used to manage your browsing session.</li>
        <li><strong>Persistent Cookies:</strong> These cookies remain on your device for a set period or until you delete them. They help us recognize you as a returning user.</li>
      </ul>

      <h2>4. Third-Party Cookies</h2>
      <p>
        In addition to our own cookies, we may also use various third-party cookies to report usage statistics, deliver advertisements, and provide certain functionality. These third parties may collect information about your online activities over time and across different websites.
      </p>

      <h2>5. Your Cookie Preferences</h2>
      <p>
        You have the right to decide whether to accept or reject cookies. You can set your browser to refuse cookies or to alert you when cookies are being sent. However, if you choose to disable cookies, some features of our website may not function properly.
      </p>
      <p>
        Most browsers allow you to:
      </p>
      <ul>
        <li>View the cookies that are currently stored on your device and delete them individually.</li>
        <li>Block third-party cookies.</li>
        <li>Block all cookies from specific sites.</li>
        <li>Block all cookies from all sites.</li>
        <li>Delete all cookies when you close your browser.</li>
      </ul>
      <p>
        For more information on how to manage cookies, consult your browser's help documentation or visit the following links:
      </p>
      <ul>
        <li><a href="https://support.google.com/chrome/answer/95647?hl=en">Google Chrome</a></li>
        <li><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">Mozilla Firefox</a></li>
        <li><a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">Safari</a></li>
        <li><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a></li>
      </ul>

      <h2>6. Changes to This Cookies Policy</h2>
      <p>
        We may update this Cookies Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on this page, and the "Last Updated" date at the top will be revised accordingly.
      </p>

      <h2>7. Contact Us</h2>
      <p>
        If you have any questions about our use of cookies or this Cookies Policy, please contact us at:
      </p>
      <ul>
        <li><strong>Company Name:</strong> MisGenie</li>
        <li><strong>Email:</strong> <a href="mailto:support@misgenie.com">support@misgenie.com</a></li>
      </ul>
    </div>
  );
};

export default CookiesPolicy;
