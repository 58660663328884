import React from 'react';
import "./index.css"
const TermsAndConditions: React.FC = () => {
    return (
        <div className="terms-conditions-container">
            <p><strong>Last Updated:</strong> 1 September 2024</p>

            <p>
                Welcome to MisGenie. By using our website and services, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully. If you do not agree with any of these terms, you should not use MisGenie’s website or services.
            </p>

            <h2>1. Introduction</h2>
            <p>
                These Terms and Conditions govern your use of the MisGenie platform, including any content, functionality, and services offered through our website. MisGenie provides a comprehensive solution for organizations of all sizes, offering features such as HRIS, payroll, performance management, recruitment, and AI-powered data analysis.
            </p>

            <h2>2. Eligibility</h2>
            <p>
                By using MisGenie, you represent and warrant that:
            </p>
            <ul>
                <li>You are at least 18 years of age or the legal age of majority in your jurisdiction.</li>
                <li>You have the authority to enter into this agreement on behalf of a company or organization, if applicable.</li>
                <li>All information you provide to MisGenie is accurate, complete, and current.</li>
            </ul>

            <h2>3. Account Registration and Responsibilities</h2>
            <p>
                To access certain features of MisGenie, you may be required to create an account. You agree to:
            </p>
            <ul>
                <li>Provide accurate, complete, and current information during registration.</li>
                <li>Keep your account information up-to-date.</li>
                <li>Maintain the confidentiality of your account credentials and restrict access to your account.</li>
                <li>Notify MisGenie immediately of any unauthorized use of your account.</li>
            </ul>

            <h2>4. Use of Services</h2>
            <p>
                You agree to use MisGenie’s services solely for lawful purposes and in accordance with these Terms and Conditions. You may not:
            </p>
            <ul>
                <li>Use the services in any way that violates applicable laws or regulations.</li>
                <li>Transmit any harmful, threatening, or offensive content through the platform.</li>
                <li>Attempt to interfere with the proper functioning of the platform or MisGenie’s infrastructure.</li>
                <li>Access or attempt to access any unauthorized areas of the platform.</li>
            </ul>

            <h2>5. Fees and Payments</h2>
            <p>
                MisGenie offers various subscription plans for accessing its services. By purchasing a subscription or using paid features, you agree to pay the applicable fees. All payments must be made in accordance with the pricing and billing terms set forth on the website. MisGenie reserves the right to change its fees and payment terms at any time. Subscription fees are non-refundable except as required by law.
            </p>

            <h2>6. Intellectual Property</h2>
            <p>
                All content, trademarks, logos, and other intellectual property available on the MisGenie platform are the property of MisGenie or its licensors. You are granted a limited, non-exclusive, and non-transferable license to access and use the platform for its intended purposes. You may not:
            </p>
            <ul>
                <li>Copy, modify, distribute, or publicly display any part of the platform without MisGenie’s prior written consent.</li>
                <li>Reverse-engineer or attempt to extract the source code of any software used on the platform.</li>
            </ul>

            <h2>7. Data Privacy and Security</h2>
            <p>
                MisGenie is committed to protecting your privacy and handling your personal data in accordance with our <a href="/privacy-policy">Privacy Policy</a>. By using our services, you consent to the collection and use of your information as outlined in the Privacy Policy.
            </p>

            <h2>8. Termination</h2>
            <p>
                MisGenie reserves the right to terminate or suspend your account at any time, with or without notice, for violating these Terms and Conditions or for any other reason at our sole discretion. Upon termination, you must immediately cease using the platform, and any licenses granted to you will be revoked.
            </p>

            <h2>9. Limitation of Liability</h2>
            <p>
                To the fullest extent permitted by law, MisGenie and its affiliates, officers, directors, employees, and agents will not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of your use or inability to use the platform or services, including but not limited to damages for loss of profits, data, or business opportunities.
            </p>

            <h2>10. Disclaimer of Warranties</h2>
            <p>
                MisGenie provides its platform and services on an "as-is" and "as-available" basis. We make no warranties or representations about the accuracy, reliability, completeness, or timeliness of the platform's content, services, or any material provided through the platform.
            </p>

            <h2>11. Indemnification</h2>
            <p>
                You agree to indemnify, defend, and hold harmless MisGenie and its affiliates from and against any claims, liabilities, damages, losses, and expenses, including reasonable legal fees, arising out of or in any way connected with your use of the platform or violation of these Terms and Conditions.
            </p>

            <h2>12. Changes to the Terms</h2>
            <p>
                MisGenie reserves the right to modify or update these Terms and Conditions at any time. Any changes will be effective immediately upon posting. It is your responsibility to review the Terms and Conditions periodically. Your continued use of the platform following the posting of any changes constitutes your acceptance of those changes.
            </p>

            <h2>13. Governing Law and Jurisdiction</h2>
            <p>
                These Terms and Conditions will be governed by and construed in accordance with the laws of [Insert Jurisdiction], without regard to its conflict of law principles. Any disputes arising from or relating to these Terms and Conditions shall be resolved exclusively in the courts of [Insert Jurisdiction].
            </p>

            <h2>14. Contact Information</h2>
            <p>
                If you have any questions about these Terms and Conditions, please contact us at:
            </p>
            <ul>
                <li><strong>Company Name</strong>: MisGenie</li>
                <li><strong>Email</strong>: <a href="mailto:support@misgenie.com">support@misgenie.com</a></li>
            </ul>
        </div>
    );
};

export default TermsAndConditions;
