import "./index.css";

const HeroSection: React.FC = () => {
  return (
    <div className="privacy-hero-container">
      <div className="privacy-hero-content-container">
        <span className="privacy-hero-heading storefront-hero-heading">
          Privacy Policy
        </span>
      </div>
    </div>
  );
};

export default HeroSection;
