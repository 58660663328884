import { PricingPage } from "../../../constants/Constants";
import { Row, Col, Collapse, Typography, Tooltip } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import "./index.css";
const { Title, Text } = Typography;
const { Panel } = Collapse;


const FAQs = () => {
  const { Faqs } = PricingPage;

  return (
    <div className="faq-outer-container">
      <div className="faq-container">
        <Row>
          <Col span={24}>
            <Title className="faq-container-heading">
              Unlock the Mysteries of{" "}
              <span className="pricing-faqs-heading-colored">Mis</span>Genie
              Pricing
            </Title>
          </Col>
        </Row>
        <Row className="faqs-subheading-row">
          <Col>
            <Text className="faq-container-subheading">
              Your Essential FAQs Answered!
            </Text>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {Faqs.map((accordion, index) => (
            <Col key={index} span={24}>
              <Collapse
                bordered={false}
                expandIconPosition="right"
                className="faqs-collapse-style"
                expandIcon={({ isActive }) =>
                  isActive ? <MinusOutlined /> : <PlusOutlined />
                }
              >
                <Panel
                  header={accordion.question}
                  key={index}
                  className="faq-collapse-panel-style"
                >
                  <div className="faq-collapse-panel-style-div">
                    <Text className="faqs-answer-style">
                      {accordion.answer}
                    </Text>
                  </div>
                </Panel>
              </Collapse>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default FAQs;
