import "./index.css";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Marquee from "react-fast-marquee";
import logo1 from "../../../assets/images/storefront-images/contact-us/Company logos/TWH.png";
import logo2 from "../../../assets/images/storefront-images/contact-us/Company logos/bitlogix.png";
import logo3 from "../../../assets/images/storefront-images/contact-us/Company logos/DigitalElites.png";
import logo4 from "../../../assets/images/storefront-images/contact-us/Company logos/EmanCreation.png";
import logo5 from "../../../assets/images/storefront-images/contact-us/Company logos/TDC.jpg";
import logo6 from "../../../assets/images/storefront-images/contact-us/Company logos/Techjaunt.png";
import logo7 from "../../../assets/images/storefront-images/contact-us/Company logos/Sapphire.png";

const ReviewsSection: React.FC = () => {
  const logos = [logo1, logo2, logo3, logo4, logo5, logo6, logo7];

  const cardContent = [
    {
      icon: <UserOutlined />,
      heading: "Shipmox",
      description:
        "From a financial perspective, HR Genie has been a wise investment. The payroll management module has reduced errors and improved accuracy, saving us money in the long run. The ability to generate customized reports has also given us valuable insights into our workforce, helping us make informed decisions.",
    },
    {
      icon: <UserOutlined />,
      heading: "Waktax",
      description:
        "HR Genie scalability was exactly what we were looking for. We started with the basic modules and have been able to add on as we’ve expanded. The interface is user-friendly which makes it easy for our employees to manage their own HR tasks, and it has helped us streamline our hiring process.",
    },
    {
      icon: <UserOutlined />,
      heading: "John Doe",
      description:
        "Empower employees to manage their profiles, request leaves, and stay updated with the company news.",
    },
  ];

  const reapeatedCardContent = cardContent.concat(cardContent);
  const repeatedLogos = logos.concat(logos);

  return (
    <div className="reviews-container">
      <h1 className="reviews-heading storefront-hero-heading">Trusted By</h1>
      <div className="reviews-carousel-container">
        <Marquee
          pauseOnHover
          pauseOnClick
          loop={0}
          autoFill={true}
          play={true}
          direction="left"
          speed={30}
        >
          {repeatedLogos.map((logo, index) => (
            <div className="slider-card">
              <img src={logo} alt="Logo" width="100px" height="auto" />

            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default ReviewsSection;
