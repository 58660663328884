import React, { useState } from "react";
import "./index.css";
import { motion } from "framer-motion";
import Dashboard from "../../../assets/images/landingPage-imges/homepageslider/content/Dashboard.svg";
import Recruitment from "../../../assets/images/landingPage-imges/homepageslider/content/Recruitment.svg";
import Genie from "../../../assets/images/landingPage-imges/homepageslider/content/AIGenie.svg";
import Payroll from "../../../assets/images/landingPage-imges/homepageslider/content/payrolls.svg";
import Attendance from "../../../assets/images/landingPage-imges/homepageslider/content/attandance(1).svg";

let tabs = [
  { id: "dashboard", lable: "Dashboard" },
  { id: "attendance", lable: "Attendance" },
  { id: "payroll", lable: "Payroll" },
  { id: "ai-genie", lable: "Ai Genie" },
  { id: "recruitment", lable: "Recruitment" },
];

const IntroSection = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const tabContents = [Dashboard, Attendance, Payroll, Genie, Recruitment];

  const headingVariants = {
    initial: {
      y: 50,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 10,
      },
    },
  };

  return (
    <div className="intro-container">
      <motion.span
        className="intro-title storefront-hero-heading"
        variants={headingVariants}
        initial="initial"
        whileInView="animate"
        viewport={{ once: true, amount: 0.5 }}
      >
        Multiple <span className="shadow-text">Dashboards</span> to Centralize{" "}
        <br />
        all of your HR Information
      </motion.span>
      <div className="tabs-container">
        {tabs.map((tab, index) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            style={{ color: activeTab === tab.id ? "white" : "black" }}
            className={`tab-item ${activeTab === tab.id ? "active" : ""}`}
          >
            {activeTab === tab.id && (
              <motion.div
                layoutId="button-pill"
                transition={{ type: "spring", duration: 0.6 }}
                className="button-pill"
              ></motion.div>
            )}
            <span className="button-text">{tab.lable}</span>
          </button>
        ))}
      </div>

      <div className="tab-content">
        {tabs.map(
          (tab, index) =>
            activeTab === tab.id && (
              <motion.img
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ type: "spring", duration: 0.6 }}
                whileHover={{ scale: 1.01 }}
                whileTap={{ scale: 0.9 }}
                src={tabContents[index]}
                alt="tab"
                className="tab-img"
              />
            )
        )}
      </div>
    </div>
  );
};

export default IntroSection;
