import Footer from "../../../common/Footer";
import HeroSection from "../../Platform/HeroSection";

const ClientManagerGenie = () => {
  return (
    <>
      <HeroSection />
      <div
        style={{
          width: "100%",
          height: "30rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1> Client Manager Genie </h1>
      </div>
      <Footer />
    </>
  );
};

export default ClientManagerGenie;
