import { motion } from "framer-motion"
import "./index.css";
const Tile = () => {
  return (
    <motion.div
      whileHover={{
        zIndex: 1,
        backgroundColor: "var(--green-color-primary)",
      }}
      transition={{
        duration: 5,
        ease: "easeOut",
      }}
      className="hero-tiles"
    />
  )
}
export default Tile;