import React from "react";
import "./index.css";
import { AboutUsPageData } from "../../../constants/Constants";
import { LinkedinFilled } from "@ant-design/icons";

export const LeadershipTeam = () => {
  const { Team } = AboutUsPageData;
  return (
    <section className="Leadership-team-section">
      <h1>Our leadership</h1>
      <div className="Team-section">
        {Team?.map((item, i) => (
          <>
            <div key={i} className="Team-card">
              <div className="Team-card-img">
                <img src={item.image} alt="team" />
              </div>
              <div className="info-text-container">
                <div className="Team-card-text">
                  {" "}
                  <p>{item.name}</p>
                  <p>{item.role}</p>
                </div>

                <a href={item.linkedin}>
                  <LinkedinFilled className="linkedin-icon" color="#000080" />
                </a>
              </div>
            </div>
          </>
        ))}
      </div>
    </section>
  );
};
export const DevelopersTeam = () => {
    const { Developers } = AboutUsPageData;
  return (
    <section className="developer-team-section">
      <h1>Our Developers</h1>
      <div className="Team-section">
        {Developers?.map((item, i) => (
          <>
            <div key={i} className="Team-card">
              <div className="Team-card-img">
                <img src={item.image} alt="team" />
              </div>
              <div className="info-text-container">
                <div className="Team-card-text">
                  {" "}
                  <p>{item.name}</p>
                  <p>{item.role}</p>
                </div>

                <a href={item.linkedin}>
                  <LinkedinFilled className="linkedin-icon" color="#000080" />
                </a>
              </div>
            </div>
          </>
        ))}
      </div>
    </section>
  );
};
