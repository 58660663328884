import "./index.css";
import automationIcon from "../../../assets/svgs/home-svgs/automation.svg";
import payrollIcon from "../../../assets/svgs/home-svgs/payroll.svg";
import AIicon from "../../../assets/svgs/home-svgs/AI.svg";
import userIcon from "../../../assets/svgs/home-svgs/user.svg";
import dashboardSvg from "../../../assets/svgs/platformSvgs/dashboards.svg";
import integerationSvg from "../../../assets/svgs/platformSvgs/integeration.svg";
import modulesSvg from "../../../assets/svgs/platformSvgs/modules.svg";
import interfaceSvg from "../../../assets/svgs/platformSvgs/interface.svg";
import { Col, Row } from "antd";

const PlatformCards: React.FC = () => {
  const content = [
    {
      id: 1,
      title: "Centralized Dashboard",
      description:
        "Get an at-a-glance view of your organization’s performance and key metrics.",
      icon: dashboardSvg,
    },
    {
      id: 2,
      title: "Customizable Modules",
      description:
        "Tailor MisGenie to fit your unique business needs with our flexible and customizable modules.",
      icon: modulesSvg,
    },
    {
      id: 1,
      title: "Integration Capabilities",
      description:
        "Seamlessly integrate MisGenie with your existing tools and systems for a unified workflow.",
      icon: integerationSvg,
    },
    {
      id: 1,
      title: "User-Friendly Interface",
      description:
        " Enjoy an intuitive and easy-to-navigate interface designed for all levels of tech-savviness.",
      icon: interfaceSvg,
    },
  ];

  return (
    <div className="platform-cards-container">
      <h1 className="platform-cards-title">How it Works?</h1>
      <div className="platform-cards-cards-container">
        <Row gutter={[32, 32]}>
          {content.map((card) => (
            <Col span={24} md={12} lg={6}>
              <div key={card.id} className="platform-cards">
                <div className="platform-cards-icon-container">
                  <img
                    src={card.icon}
                    alt={card.title}
                    width="100"
                    height="auto"
                  />
                </div>
                <div className="platform-cards-text-content">
                  <span className="platform-cards-cards-title">
                    {card.title}
                  </span>
                  <span className="platform-cards-description">
                    {card.description}
                  </span>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default PlatformCards;
