import React from "react";
import BgContentContainer from "../../../Platform/BgContentContainer";
import PlainContentContainer from "../../../Platform/PlainContantContainer";
import { PayrollConstants } from "../../../../constants/PlatformAttributeConstants";
import { storeFrontQuestion } from "../../../../common/Genie/questions";

const PlatformPayroll = () => {
  return (
    <>
      <div>
        <div>
          <BgContentContainer
            imgSrc={PayrollConstants.Payroll_Management_Empl_Benefits.img}
            heading={PayrollConstants.Payroll_Management_Empl_Benefits.heading}
            description={
              PayrollConstants.Payroll_Management_Empl_Benefits.description
            }
            questionId={1}
            arrayName={storeFrontQuestion.payroll}
          />
          <PlainContentContainer
            imgSrc={PayrollConstants.Payroll_Management_Salaries.img}
            heading={PayrollConstants.Payroll_Management_Salaries.heading}
            description={
              PayrollConstants.Payroll_Management_Salaries.description
            }
            questionId={2}
            arrayName={storeFrontQuestion.payroll}
          />
          <BgContentContainer
            imgSrc={PayrollConstants.Payroll_Management_PaySlip_Generation.img}
            heading={
              PayrollConstants.Payroll_Management_PaySlip_Generation.heading
            }
            description={
              PayrollConstants.Payroll_Management_PaySlip_Generation.description
            }
            questionId={3}
            arrayName={storeFrontQuestion.payroll}
          />
          <PlainContentContainer
            imgSrc={PayrollConstants.Payroll_Management_Overtime_Claim.img}
            heading={PayrollConstants.Payroll_Management_Overtime_Claim.heading}
            description={
              PayrollConstants.Payroll_Management_Overtime_Claim.description
            }
            questionId={4}
            arrayName={storeFrontQuestion.payroll}
          />
          <BgContentContainer
            imgSrc={PayrollConstants.Payroll_Management_Claim_Request.img}
            heading={PayrollConstants.Payroll_Management_Claim_Request.heading}
            description={
              PayrollConstants.Payroll_Management_Claim_Request.description
            }
            questionId={5}
            arrayName={storeFrontQuestion.payroll}
          />
          <PlainContentContainer
            imgSrc={PayrollConstants.Payroll_Management_Payroll_Config.img}
            heading={PayrollConstants.Payroll_Management_Payroll_Config.heading}
            description={
              PayrollConstants.Payroll_Management_Payroll_Config.description
            }
            questionId={6}
            arrayName={storeFrontQuestion.payroll}
          />
          <BgContentContainer
            imgSrc={PayrollConstants.Payroll_Management_Tax_Management.img}
            heading={PayrollConstants.Payroll_Management_Tax_Management.heading}
            description={
              PayrollConstants.Payroll_Management_Tax_Management.description
            }
            questionId={7}
            arrayName={storeFrontQuestion.payroll}
          />
        </div>
      </div>
    </>
  );
};
export default PlatformPayroll;
