import React from "react";
import "./Icons.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faBars,
  faCheckDouble,
  faCheck,
  faLock,
  faRobot,
  faSliders,
  faAward,
  faBoltLightning,
  faArrowRight,
  faBuilding,
  faPlus,
  faWarning,
  faUserAlt,
  faEnvelope,
  faUsers,
  faSpaceShuttle,
  faMessage,
  faGauge,
  faSitemap,
  faCalendarXmark,
  faChartLine,
  faBriefcase,
  faTimeline,
  faCoins,
  faBook,
  faEdit,
  faArrowRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

export const TimesIcon = () => (
  <FontAwesomeIcon
    icon={faTimes}
    size="1x"
    className="dropIcon"
    style={{ color: "gray" }}
  />
);
export const BarsIcon = () => (
  <FontAwesomeIcon
    icon={faBars}
    size="1x"
    className="dropIcon"
    style={{ color: "gray" }}
  />
);
export const CheckIcon = () => (
  <FontAwesomeIcon icon={faCheckDouble} style={{ color: "#1e90ff" }} />
);
export const CheckSingleTickIcon = () => (
  <FontAwesomeIcon icon={faCheck} style={{ color: "#1e90ff"}} />
)
export const SecurityIcon = () => (
  <FontAwesomeIcon
    icon={faLock}
    className="Ent-pg-icon"
    style={{ color: "#fff" }}
  />
);
export const RobotIcon = () => (
  <FontAwesomeIcon
    icon={faRobot}
    className="Ent-pg-icon"
    style={{ color: "#fff" }}
  />
);
export const ControlIcon = () => (
  <FontAwesomeIcon
    icon={faSliders}
    className="Ent-pg-icon"
    style={{ color: "#fff" }}
  />
);
export const AwardIcon = () => (
  <FontAwesomeIcon
    icon={faAward}
    className="Ent-pg-icon"
    style={{ color: "#fff" }}
  />
);
export const SparkleIcon = () => (
  <FontAwesomeIcon
    icon={faBoltLightning}
    className="Ent-pg-icon"
    style={{ color: "#fff" }}
  />
);
export const RightArrowIcon = () => (
  <FontAwesomeIcon icon={faArrowRight} style={{ color: "#fff" }} />
);
export const IndustryIcon = () => (
  <FontAwesomeIcon
    icon={faBuilding}
    size="4x"
    style={{ color: "#1b1b1e", marginTop: "-43px", marginBottom: "5px" }}
  />
);
export const PlusIcon = () => (
  <FontAwesomeIcon
    icon={faPlus}
    style={{ color: "#1b1b1e", marginRight: "45px" }}
  />
);
export const AlertIcon = () => (
  <FontAwesomeIcon
    icon={faWarning}
    style={{ color: "rgb(224, 79, 68)", marginRight: "5px" }}
  />
);
export const UserIcon = () => (
  <FontAwesomeIcon
    icon={faUserAlt}
    style={{ color: "#343436", marginRight: "5px" }}
  />
);
export const MailIcon = () => (
  <FontAwesomeIcon
    icon={faEnvelope}
    style={{ color: "#343436", marginRight: "5px" }}
  />
);
export const LockIcon = () => (
  <FontAwesomeIcon
    icon={faLock}
    style={{ color: "#343436", marginRight: "5px" }}
  />
);
export const OrganisationIcon = () => (
  <FontAwesomeIcon
    icon={faBuilding}
    style={{ color: "#343436", marginRight: "5px" }}
  />
);
export const EmployeeIcon = () => (
  <FontAwesomeIcon
    icon={faUsers}
    style={{ color: "#343436", marginRight: "5px" }}
  />
);
export const TitleIcon = () => (
  <FontAwesomeIcon
    icon={faSpaceShuttle}
    style={{ color: "#343436", marginRight: "5px" }}
  />
);
export const MessageIcon = () => (
  <FontAwesomeIcon
    icon={faMessage}
    className="Ent-pg-icon"
    style={{ color: "#fff" }}
  />
);
export const DashboardIcon = () => (
  <FontAwesomeIcon icon={faGauge} className="Dashboard-sidebar-icon" />
);
export const ProfileIcon = () => (
  <FontAwesomeIcon icon={faUserAlt} className="Dashboard-sidebar-icon" />
);
export const OrgIcon = () => (
  <FontAwesomeIcon icon={faSitemap} className="Dashboard-sidebar-icon" />
);
export const LMIcon = () => (
  <FontAwesomeIcon icon={faCalendarXmark} className="Dashboard-sidebar-icon" />
);
export const PRIcon = () => (
  <FontAwesomeIcon icon={faChartLine} className="Dashboard-sidebar-icon" />
);
export const REIcon = () => (
  <FontAwesomeIcon icon={faBriefcase} className="Dashboard-sidebar-icon" />
);
export const TTIcon = () => (
  <FontAwesomeIcon icon={faTimeline} className="Dashboard-sidebar-icon" />
);
export const EMIcon = () => (
  <FontAwesomeIcon icon={faUsers} className="Dashboard-sidebar-icon" />
);
export const PayRollIcon = () => (
  <FontAwesomeIcon icon={faCoins} className="Dashboard-sidebar-icon" />
);
export const PolicyIcon = () => (
  <FontAwesomeIcon icon={faBook} className="Dashboard-sidebar-icon" />
);
export const ResignIcon = () => (
  <FontAwesomeIcon
    icon={faArrowRightFromBracket}
    className="Dashboard-sidebar-icon"
  />
);

export const EditIcon = (props) => (
  <FontAwesomeIcon icon={faEdit} className={props.className} />
);
 
export const FacebookIcon = () => (
  <FontAwesomeIcon
    icon={faFacebook}
    size="1x"
    className="dropIcon"
    style={{ color: "gray" }}
  />
);
export const TwitterIcon = () => (
  <FontAwesomeIcon
    icon={faTwitter}
    size="1x"
    className="dropIcon"
    style={{ color: "gray" }}
  />
);
export const InstagramIcon = () => (
  <FontAwesomeIcon
    icon={faInstagram}
    size="1x"
    className="dropIcon"
    style={{ color: "gray" }}
  />
);
