import { ContactUsForm } from "../Form";
import "./index.css";
import { Card, Col, Row } from "antd";
import logo1 from "../../../assets/images/storefront-images/contact-us/Company logos/DigitalElites.png";
import logo2 from "../../../assets/images/storefront-images/contact-us/Company logos/TWH.png";
import logo3 from "../../../assets/images/storefront-images/contact-us/Company logos/EmanCreation.png";
import logo4 from "../../../assets/images/storefront-images/contact-us/Company logos/Sapphire.png";
import logo5 from "../../../assets/images/storefront-images/contact-us/Company logos/TDC.jpg";
import logo6 from "../../../assets/images/storefront-images/contact-us/Company logos/bitlogix.png";

const HeroSection: React.FC = () => {
  return (
    <div className="contact-us-hero-container">
      <Row gutter={[48, 48]}>
        <Col xs={0} sm={0} md={12} xl={12}  className="contact-us-hero-content-ccontainer">
          <Row gutter={[32, 32]}>
            <Col span={0} lg={0} xl={8}></Col>
            <Col span={0} md={16} xl={16}>
              <Card>
                <Row >
                  <Col
                    span={24}
                  >
                    <span className="contact-us-hero-bold-text">
                      Get in Touch
                    </span>
                  </Col>
                  <Col span={24}>
                    <span className="contact-us-hero-description">
                      We’re here to help! Whether you have questions about our
                      platform, need support, or want to explore how MisGenie
                      can transform your business, reach out to us. Our team is
                      ready to assist you.
                    </span>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={0} lg={0} xl={8}></Col>
            <Col span={0} md={16} xl={16} className="contact-us-hero-logo-section">
              <Row gutter={[10, 10]} className="contact-us-hero-icon-container">
                <Col span={24} className="contact-us-hero-bold-text-container">
                  <span className="contact-us-hero-bold-text">TRUSTED BY</span>
                </Col>
                <Col span={12} className="contact-us-hero-logo-container">
                  <img src={logo2} alt="Logo" width="100px" height="auto" />
                </Col>
                <Col span={12} className="contact-us-hero-logo-container">
                  <img src={logo6} alt="Logo" width="100px" height="auto" />
                </Col>
                <Col span={12} className="contact-us-hero-logo-container">
                  <img src={logo1} alt="Logo" width="100px" height="auto" />
                </Col>
                <Col span={12} className="contact-us-hero-logo-container">
                  <img src={logo3} alt="Logo" width="100px" height="auto" />
                </Col>
                <Col span={12} className="contact-us-hero-logo-container">
                  <img src={logo4} alt="Logo" width="100px" height="auto" />
                </Col>
                <Col span={12} className="contact-us-hero-logo-container">
                  <img src={logo5} alt="Logo" width="100px" height="auto" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24} md={12} xl={12} className="contact-form-container">
          <ContactUsForm />
        </Col>
      </Row>
    </div>
  );
};

export default HeroSection;
