import "./index.css";
import MarqueeCard from "./MarqueeCards";
import talentSvg from "../../../assets/svgs/home-svgs/talent-svg.svg";
import aiSvg from "../../../assets/svgs/home-svgs/ai-svg.svg";
import employeeSvg from "../../../assets/svgs/home-svgs/employee-svg.svg";
import attendanceSvg from "../../../assets/svgs/home-svgs/attendance-svg.svg";
import payrollSvg from "../../../assets/svgs/home-svgs/cash-svgrepo-com.svg";
import recruitmentSvg from "../../../assets/svgs/home-svgs/recruitment-svg.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const headingVariants = {
  initial: {
    y: 50,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 10,
    },
  },
};

const ModulesView: React.FC = () => {
  const navigate = useNavigate();

  const cardContent = [
    {
      icon: talentSvg,
      heading: "Talent Management",
      description:
        "Gather 360-degree feedback, conduct appraisals, and customize KPIs for your workforce.",
      buttonText: "View More",
      onButtonClick: () => {
        navigate("/platform/performance-genie");
      },
    },
    {
      icon: aiSvg,
      heading: "AI Integration",
      description:
        "Elevate the employee satisfaction through AI assistant, offering onboarding support and policy insights",
      buttonText: "View More",
      onButtonClick: () => {
        navigate("/platform/ai-genie");
      },
    },
    {
      icon: employeeSvg,
      heading: "Employee Self Service",
      description:
        "Empower employees to manage their profiles, request leaves, and stay updated with the company news.",
      buttonText: "View More",
      onButtonClick: () => {
        navigate("/platform/hr-genie");
      },
    },
    {
      icon: recruitmentSvg,
      heading: "Recruitment",
      description:
        "Build a comprehensive candidate database within the system and screen job applications efficiently.",
      buttonText: "View More",
      onButtonClick: () => {
        navigate("/platform/recruiter-genie");
      },
    },
    {
      icon: attendanceSvg,
      heading: "Attendance",
      description:
        "Enable attendance tracking with check-in/out reports, biometric integration, and geofencing for overtime.",
      buttonText: "View More",
      onButtonClick: () => {
        navigate("/platform/hr-genie");
      },
    },
    {
      icon: payrollSvg,
      heading: "Payroll",
      description:
        "Configure salary components, calculate taxes, process payroll, and generate compliance reports.",
      buttonText: "View More",
      onButtonClick: () => {
        navigate("/platform/payroll-genie");
      },
    },
  ];

  return (
    <div className="modules-container">
      <motion.span
        className="modules-title storefront-hero-heading"
        variants={headingVariants}
        initial="initial"
        whileInView="animate"
        viewport={{ once: true, amount: 0.5 }}
      >
        All the <span className="shadow-text">Modules</span> you Need
      </motion.span>
      <div className="marquee-container">
        <Swiper
          modules={[Pagination, Autoplay]}
          spaceBetween={30}
          slidesPerView={3}
          autoplay={{ delay: 2000 }}
          pagination={{ clickable: true }}
        >
          {cardContent.map((card, index) => (
            <SwiperSlide>
              <MarqueeCard
                icon={card.icon}
                heading={card.heading}
                description={card.description}
                buttonText={card.buttonText}
                onButtonClick={card.onButtonClick}
                className="marquee-card"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ModulesView;
