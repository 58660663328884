
import TermsAndConditions from "./Components";
import Footer from "../../common/Footer";
import DemoCard from "../Home/DemoCard";
import HeroSection from "./HeroSection";

const TermsandConditionsPage = () => {
  return (
    <>
      <HeroSection />
      <TermsAndConditions />
      <DemoCard />
      <Footer />
    </>
  );
};

export default TermsandConditionsPage;
