
import React, { useEffect } from "react";
import "./index.css";

// this component will redirect user to misgieni app url

const RouteRedirect: React.FC = () => {
    
    useEffect(() => {
        // const currentUrl = "https://misgenie.io/hrm/confirm/MisgenieProductionTesting#"
        const currentUrl = window.location.href;
   
        const subdomain = currentUrl.split("/confirm/")[1].split("#")[0].split("?")[0];
        
        const newUrl = `https://${subdomain}.misgenie.app/hrm`;
        window.location.href = newUrl
    }, []);

    return (
        <div className="redirect-page">
            <h3>Almost there! We're taking you right to your domain portal......</h3>
        </div>
    );
};

export default RouteRedirect;