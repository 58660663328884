import "./index.css";
import genieLamp from "../../../assets/svgs/about-us-svgs/genie.svg";


const HeroSection: React.FC = () => {
  return (
    <div className="about-us-hero-container">
      <img src={genieLamp} alt="Genie Icon" className="about-us-hero-icon" />
      <span className="about-us-hero-heading">Transforming Businesses with Innovative Solutions</span>
    </div>
  );
};

export default HeroSection;
