import React from "react";
import BgContentContainer from "../../../../Platform/BgContentContainer";
import PlainContentContainer from "../../../../Platform/PlainContantContainer";
import { AttendanceConstants } from "../../../../../constants/PlatformAttributeConstants";
import { storeFrontQuestion } from "../../../../../common/Genie/questions";

const PlatformAttendance = () => {
  return (
    <>
      <div>
        <div>
          <BgContentContainer
            imgSrc={AttendanceConstants.Attendance_Management_Time_Sheet.img}
            heading={
              AttendanceConstants.Attendance_Management_Time_Sheet.heading
            }
            description={
              AttendanceConstants.Attendance_Management_Time_Sheet.description
            }
            questionId={1}
            arrayName={storeFrontQuestion.attendance}
          />
          <PlainContentContainer
            imgSrc={
              AttendanceConstants.Attendance_Management_Attendance_Logs.img
            }
            heading={
              AttendanceConstants.Attendance_Management_Attendance_Logs.heading
            }
            description={
              AttendanceConstants.Attendance_Management_Attendance_Logs
                .description
            }
            questionId={2}
            arrayName={storeFrontQuestion.attendance}
          />
          <BgContentContainer
            imgSrc={
              AttendanceConstants.Attendance_Management_Leave_Approval.img
            }
            heading={
              AttendanceConstants.Attendance_Management_Leave_Approval.heading
            }
            description={
              AttendanceConstants.Attendance_Management_Leave_Approval
                .description
            }
            questionId={3}
            arrayName={storeFrontQuestion.attendance}
          />
        </div>
      </div>
    </>
  );
};
export default PlatformAttendance;
