import React from "react";
import { Triangle } from "react-loader-spinner";
import "./index.css"; 

export const TriangleLoader: React.FC = () => {
  return (
    <div className="blur-screen">
      <div className="loader-wrapper">
        <Triangle
          height="60"
          width="60"
          color="var(--green-color-primary-light)"
          visible={true} 
          ariaLabel="triangle-loading"
        />
      </div>
    </div>
  );
};