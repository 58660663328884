import "./index.css";
import { motion } from "framer-motion";
import contactSvg from "../../../assets/svgs/home-svgs/contact-svg.svg";
import { useNavigate } from "react-router-dom";

const DemoCard: React.FC = () => {
  const navigate = useNavigate();
  const handleDemoRequestClick = () => {
    navigate("/demo");
  };

  return (
    <div className="demo-container">
      <div className="demo-card">
        <img src={contactSvg} alt="Contact Image" width="auto" height="110" className="demo-card-contact-image" />
        <h1 className="demo-card-heading storefront-hero-heading">Experience the Power of MisGenie Today</h1>
        <p className="demo-card-description storefront-description">
          Ready to transform your organization? Contact us today to schedule a
          demo and see how MisGenie can revolutionize your business.
        </p>
        <button
          type="button"
          onClick={handleDemoRequestClick}
          className="demo-card-button"
        >
          Request a Demo
        </button>
      </div>
    </div>
  );
};

export default DemoCard;
