import Footer from "../../common/Footer";
import DemoCard from "../Home/DemoCard";
import ServicesCards from "./Cards";
import HeroSection from "./HeroSection";
import { ServicesForm } from "./ServicesForm";

const Services = () => {
  return (
    <>
      <HeroSection />
      <ServicesCards />
      <ServicesForm />
      <DemoCard />
      <Footer />
    </>
  );
};

export default Services;
