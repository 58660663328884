import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { Col, Row, Tag } from "antd";
import GenieDrawer from "../../../common/Genie/GenieDrawer";

interface Props {
  svg?: string;
  iconDisplay?: boolean;
  imgSrc?: string;
  heading?: string;
  description?: string;
  handleButtonClick?: () => void;
  questionId?: number | null;
  arrayName?: any[];
  buttonDisplay?: boolean;
}

const PlainContentContainer: React.FC<Props> = ({
  svg,
  iconDisplay,
  imgSrc,
  heading,
  description,
  handleButtonClick,
  questionId,
  arrayName,
  buttonDisplay,
}) => {
  const imgRef = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleTagClick = () => {
    setDrawerVisible(true);
    setIsClicked(true);
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setLoaded(true);
            observer.unobserve(entry.target);
          }
        });
      },
      {
        rootMargin: "100px",
      }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  return (
    <div className="plain-content-container">
      <div className="plain-content-div">
        <div className="plain-content-right-div">
          {iconDisplay && (
            <img src={svg} alt="Management Icon" width="100px" height="auto" />
          )}
          <span className="plain-content-heading">{heading}</span>
          <span className="plain-content-description">{description}</span>
          {buttonDisplay && (
            <button
              className="platform-custom-button"
              onClick={handleButtonClick}
            >
              Learn More
            </button>
          )}
          <Row>
            {questionId && arrayName && (
              <Col span={24} className="genie-tag-style-container">
                <Tag
                  className="genie-tag-style"
                  onClick={() => handleTagClick()}
                >
                  {arrayName[questionId].question.length > 40
                    ? `${arrayName[questionId].question.substring(0, 40)}...`
                    : arrayName[questionId].question}
                </Tag>
              </Col>
            )}
          </Row>
        </div>
        <div className="plain-content-left-div">
          <img src={imgSrc} alt="System Image" className="plain-content-img" />
        </div>
      </div>
      {questionId && arrayName && (
        <GenieDrawer
          visible={drawerVisible}
          onClose={handleCloseDrawer}
          questionId={arrayName[questionId].id}
          questionArray={arrayName}
        />
      )}
    </div>
  );
};

export default PlainContentContainer;
