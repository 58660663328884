import { motion } from "framer-motion";
import Tile from "../../../components/Tile";
import "./index.css";
import { HomePageConstants } from "../../../constants/Constants";
import { useNavigate } from "react-router-dom";

const headingVariants = {
  initial: {
    y: 100,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 10,
    },
  },
};

const HeroSection = () => {
  const { subtitle } = HomePageConstants.Hero_Section;
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/demo");
  };

  return (
    <main className="hero-main">
      <section className="hero-grid">
        {Array.from(Array(20 * 12), (i) => (
          <Tile key={i} />
        ))}
      </section>
      <div className="hero-content">
        <motion.h1
          variants={headingVariants}
          initial="initial"
          whileInView="animate"
          viewport={{ once: true, amount: 0.5 }}
          className="hero-title storefront-hero-heading"
        >
          Maximize Your Organization's Potential with <br />
          <br />
          <span className="hero-gradient-text">MisGenie</span>
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.25 }}
          className="hero-subtitle"
        >
          {subtitle}
        </motion.p>
        <motion.button
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", stiffness: 400, damping: 17 }}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="learn-more"
          onClick={handleButtonClick}
        >
          <span className="circle" aria-hidden="true">
            <span className="icon arrow"></span>
          </span>
          <span className="button-text">Request A Demo</span>
        </motion.button>
      </div>
    </main>
  );
};

export default HeroSection;
