import React, { useState } from "react";
import "./index.css";
import { Heading } from "../../../common/Elements/Composition";
import { AboutUsPageData } from "../../../constants/Constants";
import Problem from "../../../assets/images/aboutpage/problem.png";
import Solution from "../../../assets/images/aboutpage/solution.png";
import Roadmap from "../../../assets/images/aboutpage/roadmap.png";

export const InfoSection = () => {
  const { Infosection } = AboutUsPageData;
  const [activeTab, setActiveTab] = useState("section1");

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
  };

  return (
    <div className="Info-main-container">
      <Heading className="Info-Heading">
        <h1>We are MisGenie</h1>
        <p>Innovative Solutions for Operational Excellence</p>
      </Heading>
      <div className="Info-tab-container">
        <div className="Info-tabs">
          <button
            onClick={() => handleTabClick("section1")}
            className={activeTab === "section1" ? "active" : ""}
          >
            Conquering Challenges
          </button>
          <button
            onClick={() => handleTabClick("section2")}
            className={activeTab === "section2" ? "active" : ""}
          >
            Our Secret Sauce
          </button>
          <button
            onClick={() => handleTabClick("section3")}
            className={activeTab === "section3" ? "active" : ""}
          >
            The Odyssey
          </button>
        </div>

        <div className="Info-tab-content">
          {activeTab === "section1" && (
            <div>
              <section>
                {" "}
                <h1>Facing Challenges Head-On</h1>
                <p>{Infosection.challange}</p>
              </section>

              <img src={Problem} alt="Problem" />
            </div>
          )}
          {activeTab === "section2" && (
            <div>
              <section>
                {" "}
                <h1> Crafting Solutions</h1>
                <p> {Infosection.solution}</p>
              </section>

              <img src={Solution} alt="Solution" />
            </div>
          )}
          {activeTab === "section3" && (
            <div>
              <section>
                {" "}
                <h1> Navigating Our Path</h1>
                <p>{Infosection.OurPath}</p>
              </section>

              <img src={Roadmap} alt="Roadmap" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
