import React from "react";

 
export const Container = (prop:any) => {
  return (
    <div className={prop.className} onClick={prop.onClick} key={prop.key}>
      {prop.children}
    </div>
  );
};
 
export const Section = (props:any) => {
  return <section className={props.className}>{props.children}</section>;
};

 
export const Button = (props:any) => {
  return (
    <button
      className={props.className}
      onClick={props.onClick}
      type={props.type}
    >
      {props.children}
    </button>
  );
};

 
export const Heading = (prop:any) => {
  return <div className={prop.className}>{prop.children}</div>;
};
 
export const Form = (prop:any) => {
  return (
    <form className={prop.className} onSubmit={prop.onSubmit}>
      {prop.children}
    </form>
  );
};
