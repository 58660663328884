import React from "react";
import { Col, Row, Button, Form, Input, Select, message } from "antd";
import "./index.css";
import { storeServicesFormData } from "../services";

function encode(data: any) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + `=` + encodeURIComponent(data[key]))
    .join(`&`);
}

const { Option } = Select;

export const ServicesForm = () => {
  const [form] = Form.useForm();
  const formName = `Services`;

  const onFinish = async (values: any) => {
    const body = {
      name: values.name,
      email: values.email,
      contactNo: values.contactnumber,
      companyName: values.organization,
      address: values.address,
      requestServices: values.services,
    };

    const data = await storeServicesFormData(body);

    if (data.status == 201) {
      message.success("Form submitted successfully!");
      form.resetFields();
    } else {
      message.error("Sorry! There is some internal error.");
    }
  };

  return (
    <>
      <div>
        <Row>
          <Col span={24} className="services-form-container-col">
            <div className="services-form-container">
              <Form
                layout="vertical"
                className="services-form-items-container"
                form={form}
                onFinish={onFinish}
                name={formName}
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <Row>
                  <Col span={24}>
                    <span className="services-form-heading">
                      Interested in our Services?
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <span className="services-form-description">
                      Submit a request to avail our services or contact us at
                      <a
                        href="mailto:support@misgenie.com"
                        className="services-form-description-mail"
                      >
                        support@misgenie.com
                      </a>
                      .
                    </span>
                  </Col>
                </Row>
                <Row gutter={32} className="services-form-inputs-container">
                  <Col span={24} lg={12}>
                    <Form.Item
                      name="name"
                      label={
                        <span className="services-form-input-label">Name</span>
                      }
                      rules={[{ required: true }]}
                    >
                      <Input
                        type="full-name"
                        placeholder="Enter your Name ..."
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={12}>
                    <Form.Item
                      name="email"
                      label={
                        <span className="services-form-input-label">
                          Email Address
                        </span>
                      }
                      rules={[{ required: true }]}
                    >
                      <Input
                        type="email"
                        placeholder="Enter your Email Address ..."
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24} lg={12}>
                    <Form.Item
                      name="contactnumber"
                      label={
                        <span className="services-form-input-label">
                          Contact Number
                        </span>
                      }
                    >
                      <Input
                        type="contact-number"
                        placeholder="Enter your Contact Number ..."
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={12}>
                    <Form.Item
                      name="organization"
                      label={
                        <span className="services-form-input-label">
                          Organization/Company
                        </span>
                      }
                    >
                      <Input
                        type="text"
                        placeholder="Enter your Organization/Company Name ..."
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24} lg={12}>
                    <Form.Item
                      name="address"
                      label={
                        <span className="services-form-input-label">
                          Address
                        </span>
                      }
                      rules={[{ required: true }]}
                    >
                      <Input type="text" placeholder="Enter your Address ..." />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={12}>
                    <Form.Item
                      name="services"
                      label={
                        <span className="services-form-input-label">
                          Services List
                        </span>
                      }
                      rules={[{ required: true }]}
                    >
                      <Select
                        showSearch
                        placeholder="Select sevices, you want to avail!"
                        allowClear
                        mode="tags"
                      >
                        <Option value="hrstrategy">HR Strategy</Option>
                        <Option value="recruitment">Recruitment</Option>
                        <Option value="training">Training</Option>
                        <Option value="employee">Employee Engagement</Option>
                        <Option value="organization">
                          Organizational Development
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} className="services-form-button-container">
                    <Button
                      className="services-custom-button"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
