import Footer from "../../common/Footer";
import PricingForm from "../Pricing/PricingForm";
import HeroSection from "./HeroSection";

const Demo = () => {
  return (
    <>
        <HeroSection />
        <PricingForm />
        <Footer />
    </>
  )
}

export default Demo;
