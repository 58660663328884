import Footer from "../../common/Footer";
import DemoCard from "../Home/DemoCard";
import FAQs from "./Faqs";
import HeroSection from "./HeroSection";
import PricingForm from "./PricingForm";

const Pricing = () => {
  return (
    <>
      <HeroSection />
      <PricingForm />
      <FAQs />
      <DemoCard />
      <Footer />
    </>
  );
};

export default Pricing;
