import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import "./index.css";
import logo from "../../assets/images/Logo.svg";
import logoWhite from "../../assets/images/logo-white.svg";
import "boxicons";

interface NavbarProps {
  isScrolled: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ isScrolled }) => {
  useEffect(() => {
    const menuIcon = document.querySelector("#menu-icon");
    const navbar = document.querySelector(".storefront-navbar");
    const navbg = document.querySelector(".storefront-nav-bg");

    const toggleMenu = () => {
      menuIcon?.classList.toggle("bx-x");
      navbar?.classList.toggle("active");
      navbg?.classList.toggle("active");
    };

    menuIcon?.addEventListener("click", toggleMenu);

    return () => {
      menuIcon?.removeEventListener("click", toggleMenu);
    };
  }, []);

  return (
    <>
      <div className="storefront-header">
        <Link to="/">
          <img
            src={isScrolled ? logo : logoWhite}
            alt="Logo"
            className="nav-logo-image"
          />
        </Link>
        <i>
          <IoMenu className="bx bx-menu" id="menu-icon" />
        </i>
        <nav className="storefront-navbar">
          <Link to="/platform">
            <span className={`${isScrolled && "nav-item--scrolled"}`}>
              Solutions
            </span>
          </Link>
          <Link to="/pricing">
            <span className={`${isScrolled && "nav-item--scrolled"}`}>
              Pricing
            </span>
          </Link>
          <Link to="/services">
            <span className={`${isScrolled && "nav-item--scrolled"}`}>
              Services
            </span>
          </Link>
          <Link to="/about-us">
            <span className={`${isScrolled && "nav-item--scrolled"}`}>
              About Us
            </span>
          </Link>
          <Link to="/contact-us">
            <button className="nav__cta">Contact Us</button>
          </Link>
        </nav>
      </div>
      <div className="storefront-nav-bg"></div>
    </>
  );
};

export default Navbar;
